export default {
  unitMultiplier: {
    g: 1000,
    gr: 1000,
    kg: 1,
    hg: 10,
    dag: 100,
    ml: 1000
  },
  getMultiplier(product) {
    // console.log(product);
    let weightUnitSelling = (
      product.productInfos.WEIGHT_UNIT_SELLING || "kg"
    ).toLowerCase();
    let weightBaseUnit = (
      product.productInfos.WEIGHT_UNIT_BASE || "kg"
    ).toLowerCase();

    return (
      this.unitMultiplier[weightUnitSelling] /
      this.unitMultiplier[weightBaseUnit]
    );
  },
  encodeProductQuantity(product, quantity) {
    return product.productInfos.TIPOLOGIA === "Sfuso"
      ? quantity / this.getMultiplier(product)
      : quantity;
  },
  decodeProductQuantity(product, quantity) {
    return product.productInfos.TIPOLOGIA === "Sfuso"
      ? quantity * this.getMultiplier(product)
      : quantity;
  },
  getProductFullnameForToast(product) {
    return global.vm.lodash.join(
      [product.name, product.shortDescr, product.descr],
      " "
    );
  },
  getProductQuantityForToast(item) {
    if (global.vm.lodash.isNil(item)) return 0;
    if (item.product.productInfos.TIPOLOGIA == "Sfuso") {
      //Handling floating point decimals issues
      var val = (item.weight * 100).toFixed(0);
      val = val * this.getMultiplier(item.product);
      val = val / 100;
      return val;
    } else {
      return item.quantity;
    }
  },
  getProductUnitForToast(product) {
    if (product.productInfos) {
      if (product.productInfos.TIPOLOGIA == "Sfuso") {
        return product.productInfos.WEIGHT_UNIT_SELLING;
      } else {
        return product.productInfos.UNIT_SELLING != null
          ? product.productInfos.UNIT_SELLING
          : "pz";
      }
    }
  }
};
