import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "./i18n";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    //disable: true,
    light: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          base: "#002c6c",
          lighten1: "#c4d7ed",
          lighten2: "#F0F5FA",
          darken1: "#8a8a8a"
        },
        secondary: {
          base: "#cd3c0d",
          darken1: "#2a4b91",
          lighten1: "#f26334",
          lighten2: "#aee7e1"
        },
        grey: {
          base: "#c4c4c4",
          darken1: "#636363",
          darken2: "#424242",
          darken3: "#262626",
          lighten1: "#868686",
          lighten2: "#E5E5E5",
          lighten3: "#F8F8F8"
        },
        red: "#E13333",
        accent: "#DEECE9",
        link: "#00799e"
      }
    }
  },
  breakpoint: {
    thresholds: {
      lg: 1585
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    values: {
      search: "icon-search",
      heart: "icon-heart",
      heartFull: "icon-heartfull",
      menu: "icon-burgermenu",
      profile: "icon-profile",
      cart: "icon-cart",
      minus: "icon-minus",
      plus: "icon-plus",
      edit: "icon-edit",
      fastSearch: "icon-fast-search",
      print: "icon-print",
      delete: "icon-delete",
      consegna: "icon-consegna",
      promo: "icon-promo",
      rightmenu: "icon-dots",
      profilefull: "icon-profilefull",
      star: "icon-star",
      starfull: "icon-starfull",
      reorder: "icon-reorder",
      invoice: "icon-invoice",
      menuMobile: "icon-menu-mobile"
    }
  }
});
