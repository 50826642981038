import axios from "@/plugins/axios";
export default {
  getAvailableImageTypeShots(cartId) {
    return axios
      .get("ebsn/api/theone-order/get-sliced-image-format?cart_id=" + cartId)
      .then(data => {
        return data.data.data;
      })
      .catch(() => {
        return Promise.reject();
      });
  }
};
