<template>
  <div class="fill-height mt-1">
    <v-container fluid fill-height>
      <v-layout>
        <v-flex xs12 mt-5>
          <v-form ref="form" v-model="valid" @submit.prevent="handleTechLogin">
            <v-card class="elevation-0">
              <div class="d-flex align-center flex-column mb-3">
                <h1>{{ $t("login.techTitle") }}</h1>
                <h3 class="font-weight-regular">
                  {{ $t("login.subtitle") }}
                </h3>
              </div>
              <v-spacer></v-spacer>
              <v-card-text>
                <div>
                  <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    type="email"
                    label="Login"
                    autocomplete="off"
                    required
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    label="Password"
                    autocomplete="off"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="passwordRules"
                    @click:append="toggleShowPassword"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <!-- <div class="d-flex align-center justify-space-between">
                  <v-checkbox
                    color="primary"
                    class="ma-0"
                    hide-details
                    v-model="rememberMe"
                  >
                    <template v-slot:label>
                      <span>{{ $t("login.rememberMe") }}</span>
                    </template>
                  </v-checkbox>
                  <div v-if="enableRegistration">
                    <a
                      @click="goToResetPassword"
                      class="text-decoration-underline"
                    >
                      <strong>{{ $t("login.rememberPassword") }}</strong>
                    </a>
                  </div>
                  <div v-else-if="!$platform_is_cordova">
                    <a href="#" class="text-decoration-underline">{{
                      $t("login.rememberPassword")
                    }}</a>
                  </div>
                </div> -->

                <ResponseMessage :response="response" />
                <v-row no-gutters justify="center" class="mt-6">
                  <v-col cols="12">
                    <v-btn
                      :loading="loading"
                      type="submit"
                      class="primary"
                      large
                      text
                      width="100%"
                    >
                      {{ $t("login.buttonLogin") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- <v-divider></v-divider> -->
              <!-- <v-card-actions
                v-if="enableRegistration"
                class="d-flex flex-column mt-3"
              >
                <span class="mb-4">
                  {{ $t("login.messageRegister") }}
                </span>
                <v-btn
                  @click="handleRegister"
                  large
                  outlined
                  class="primary--text"
                  text
                  width="100%"
                >
                  {{ $t("login.buttonRegister") }}
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ResponseMessage from "@/components/ResponseMessage";

import pushNotification from "~/mixins/pushNotification";

import form from "~/mixins/form";
import { mapActions, mapGetters } from "vuex";
import {
  requiredValue,
  isEmail,
  isPasswordMinimumLength
} from "@/validator/validationRules";

export default {
  name: "Login",
  props: {
    hideRegistration: { type: Boolean, required: false, default: false }
  },
  mixins: [form, pushNotification],
  components: { ResponseMessage },
  data() {
    return {
      loading: null,
      showPassword: false,
      email: "",
      password: "",
      rememberMe: true,
      response: {},
      valid: true,
      lazy: true,
      emailRules: [requiredValue("Digitare l'login"), isEmail()],
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      errors: [],
      invalid: null
    };
  },
  computed: {
    enableRegistration() {
      return global.config.registration;
    }
  },
  methods: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart",
      doTechLogin: "cart/doTechLogin"
    }),
    goToResetPassword() {
      this.$router.push({
        name: "ResetPasswordRequest"
      });
      this.$emit("submit", false);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async handleSubmit(e) {
      e.preventDefault();
      if (this.password.length > 0) {
        try {
          this.loading = true;
          this.response = {};
          await this.doLogin({
            email: this.email,
            password: this.password,
            rememberMe: this.rememberMe
          });
          var _this = this;
          // SE AUTENTICATO REGISTRA IL DEVICE PER PUSH NOTIFICATION
          if (_this.isAuthenticated && global.config.pushNotificationEnabled) {
            console.log("IS AUTHENTICATED AND PUSH ENABLED");
            this.registerFirebaseDevice();
          }
          this.$emit("submit", true);
        } catch (response) {
          this.response = response;
        } finally {
          this.loading = false;
        }
      }
    },
    async handleTechLogin(e) {
      e.preventDefault();
      if (this.password.length > 0) {
        try {
          this.loading = true;
          this.response = {};
          let response = await this.doTechLogin({
            email: this.email,
            password: this.password
          });
          if (response.data && response.data.user) {
            this.$emit("submit");
            await this.$router.push({ name: "Home" });
            this.$router.go();
          }
          console.log("RESPONSES", response);
          this.response = response.response;
        } catch (response) {
          this.response = response;
        } finally {
          this.loading = false;
        }
      }
    },
    handleRegister() {
      this.$router.push({
        name: "RegistrationUser",
        path: "/registration-user"
      });
      this.$emit("submit", false);
    }
  }
};
</script>
