import Vue from "vue";
import { DateTime, Settings } from "luxon";
Settings.defaultLocale = "it";
const InvalidReturn = "";

Object.defineProperties(Vue.prototype, {
  $DateTime: {
    get() {
      return DateTime;
    }
  }
});

Vue.filter("formatDate", (value, method, fromFormat, toFormat) => {
  let day = value;
  let m;
  if (fromFormat) {
    m = DateTime[method](day, fromFormat);
  } else {
    m = DateTime[method](day);
  }
  if (m.isValid) {
    var mf = m.toFormat(toFormat);
    return mf;
  }
  return day;
});

Vue.filter("luxon", (value, method, params) => {
  let d = DateTime.fromJSDate(value);
  if (!d.isValid) return InvalidReturn;
  if (method) {
    return d[method](params);
  }
});
