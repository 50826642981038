global.config = {
  google_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
  googleGeocodeParams: {
    components: "country:it|administrative_area:Lombardia"
  },
  baseUrl: "",
  proximityDefaultDistance: "10000.0",
  showSectors: false,
  showInvoiceForWarehouseId: [89],
  dksId: 89,
  hashNotReload: ["/cart/", "/payment"],
  filterEmptyCategory: true,
  dayWordsSaved: 30,
  version: process.env.VUE_APP_VERSION,
  minProfileLevel: 2,
  registration: true,
  checkVersionInterval: 1000 * 60 * 10, //ogni 10 minuti
  reloadAfterMillis: 1000 * 60 * 30, //dopo 30 minuti di inattività restarta la app
  updateCart: false,
  domainWhitelist: [
    "www.digitelematica.it",
    "digitelematica.it",
    "test-marketsuite.digitelematica.org",
    "localhost"
  ],
  pushNotificationEnabled: true,
  urlScheme: "ebsnmarketsuite://",
  deeplinks: {
    "/profile/orders": "/profile/orders",
    "/checkout": "/checkout",
    "/search": "/search",
    "/product/:slug": "/product/:slug",
    "/category/*": "/category/*",
    "/profile/orders/:orderId": "/profile/orders/:orderId"
  },
  defaultStoreName: "Store Globale"
};
