/* eslint-disable no-undef */
import Vue from "vue";
import VueRouter from "vue-router";
import VueRouterBackButton from "vue-router-back-button";
//import Login from "@/components/Login";
import routes from "./routes/index.js";
import store from "../store";

Vue.use(VueRouter);
console.log(process.env.VUE_APP_ROUTER_MODE);
const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE, // "hash" per Cordova. "History" per Browser
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    //savedPosition funziona solo quando navigo con i tasti del browser e non tramite i link dell'app
    if (savedPosition) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 500);
      });
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (Vue.$cookies.get("X-Ebsn-Session")) {
      next();
    } else if (store && store.getters["cart/isAuthenticated"] == false) {
      next({ name: "Home" });
    } else {
      if (to.matched.some(record => record.meta.isAdmin)) {
        if (store && store.getters.isSsoUser == true) {
          next();
        } else {
          next({
            name: "userboard"
          });
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});
Vue.use(VueRouterBackButton, { router });

export default router;
