import Vue from "vue";
import VueGtag from "vue-gtag";
import router from "../router";
import AnalyticsService from "~/service/analyticsService";
import { bootstrap, optIn, optOut } from "vue-gtag";

Vue.use(
  VueGtag,
  {
    config: {
      id: "UA-53405204-28",
      params: {
        send_page_view: false
      }
    },
    pageTrackerTemplate(to) {
      return {
        page_title: to.name,
        page_path: to.path,
        page_location:
          process.env.VUE_APP_ROUTER_MODE === "history"
            ? window.location.href
            : window.location.protocol +
              "//" +
              window.location.host +
              window.location.href.substring(
                window.location.href.lastIndexOf("#") + 1
              )
      };
    },
    bootstrap: false
  },
  router
);

export default {
  enable() {
    // init after cookie
    let gtagInitialized = AnalyticsService.getInitialized();
    if (gtagInitialized === undefined) {
      bootstrap().then(function() {
        // all done
        AnalyticsService.configureCustomMap();
      });
    } else if (gtagInitialized === false) {
      AnalyticsService.setInitialized(true);
      optIn();
    }
  },
  disable() {
    if (AnalyticsService.getInitialized() == true) {
      optOut();
      AnalyticsService.setInitialized(false);
    }
  }
};
