import axios from "@/plugins/axios";
const qs = require("qs");

export default {
  resetPassword(email, token, password) {
    let url = "/ebsn/api/registration-card/password_reset";
    return axios
      .post(url, qs.stringify({ email, token, password }))
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  },
  getResetPasswordToken(email) {
    let url = "/ebsn/api/registration/password_reset_token_request";
    return axios
      .get(url, {
        params: { e_mail: email }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  },
  confirmRegistration(email, controlCode, newEmail) {
    let url = "/ebsn/api/registration/registration_confirm";
    return axios
      .get(url, {
        params: {
          e_mail: email,
          reg_control_code: controlCode,
          new_email: newEmail
        }
      })
      .then(response => {
        return response.data.response;
      });
  },
  updatePassword(oldPassword, newPassword) {
    let url = "/ebsn/api/registration/update_password";
    return axios
      .get(url, {
        params: { old_password: oldPassword, new_password: newPassword }
      })
      .then(data => {
        return data.data;
      });
  },
  async addProductReset(product, quantity) {
    var items = [{ product: product, quantity: quantity }];
    return this.addProductsReset(items);
  },
  async addProductsReset(items) {
    let params = {};
    params.items = [];

    for (var i = 0; i < items.length; i++) {
      let product = items[i].product;
      items[i].originalQuantity = items[i].quantity;
      items[i].quantity = 1;

      let item = {
        productId: product.productId,
        quantity: 1,
        useWpId: product.wpId
      };

      //creo parametro select options
      let optionArray = [];
      if (!item.notes) {
        item.notes = optionArray.join(", ");
      }
      item.infos = {};
      params.items.push(item);
    }

    return axios
      .post("/ebsn/api/theone-cart/add-reset", params, {
        params: { store_id: 5, show_sectors: global.config.showSectors }
      })
      .then(data => {
        //show toaster..
        return data.data.data;
      });
  }
};
