import axios from "@/plugins/axios";

export default {
  register(externalId, device) {
    console.log("::: SERVICE REGISTER ::::: ", { externalId, device });
    return axios
      .get("/ebsn/api/externalregistration/register", {
        params: {
          external_id: externalId,
          model: device.model,
          platform: device.platform,
          platform_version: device.version,
          manufacturer: device.manufacturer,
          uuid: device.uuid
        }
      })
      .then(data => {
        return data;
      });
  },
  unRegister(device) {
    console.log("::: SERVICE UNREGISTER ::::: ", device);
    return axios
      .get("/ebsn/api/externalregistration/unregister", {
        params: {
          platform: device.platform,
          uuid: device.uuid
        }
      })
      .then(data => {
        return data;
      });
  }
};
