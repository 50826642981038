import axios from "@/plugins/axios";

export default {
  getCompanies(user) {
    return axios
      .get("/ebsn/api/theone-company/get-companies?uid=" + user)
      .then(data => {
        return data.data;
      });
  },
  chageCompanies(id) {
    return axios
      .get("/ebsn/api/theone-company/change-company?company_id=" + id)
      .then(data => {
        return data.data.data;
      });
  }
};
