import Vue from "vue";
const SAVE_WORD = "SAVE_WORD";
const DELETE_WORD = "DELETE_WORD";
const ADD_FAST_SEARCH = "ADD_FAST_SEARCH";
const REMOVE_FAST_SEARCH = "REMOVE_FAST_SEARCH";
const DELETE_ALL_WORDS = "DELETE_ALL_WORDS";

const state = {
  words: [],
  fastSearchList: []
};

function diff(date) {
  const diffTime = Math.abs(new Date() - date);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

const getters = {
  commonSearch: state => {
    let newItems = [];
    if (state.words.length > 0) {
      newItems = Vue._.clone(state.words);
      Vue._.each(newItems, function(item) {
        Vue._.assign(item, { type: "Ricerche" });
      });
      newItems.unshift({ header: "Ultime Parole Cercate" });
    }

    return newItems;
  }
};

const actions = {
  saveWord({ commit }, payload) {
    commit(SAVE_WORD, payload);
  },
  deleteWord({ commit }, payload) {
    commit(DELETE_WORD, payload);
  },
  deleteAllWords({ commit }) {
    commit(DELETE_ALL_WORDS);
  },
  addFastSearchWord({ commit }, payload) {
    commit(ADD_FAST_SEARCH, payload);
  },
  removeFastSearchWord({ commit }, payload) {
    commit(REMOVE_FAST_SEARCH, payload);
  }
};

const mutations = {
  [SAVE_WORD](state, value) {
    var index = global.vm.lodash.findIndex(state.words, function(w) {
      return w && w.name && w.name.toLowerCase() === value.toLowerCase();
    });
    if (index !== -1) {
      // Se la parola viene trovata, aumenta il count e si aggiorna
      var count = state.words[index].counter + 1;
      state.words.splice(index, 1, {
        name: value,
        counter: count,
        timestamp: new Date().getTime()
      });
    } else {
      state.words.push({
        name: value,
        counter: 1,
        timestamp: new Date().getTime()
      });
    }
    var wordsList = global.vm.lodash.filter(state.words, w => {
      var days = diff(w.timestamp);
      return days < global.config.dayWordsSaved;
    });
    state.words = global.vm.lodash.orderBy(wordsList, ["counter"], ["desc"]);
  },
  [DELETE_WORD](state, item) {
    var index = global.vm.lodash.findIndex(state.words, function(w) {
      return w.name.toLowerCase() === item.name.toLowerCase();
    });
    if (index !== -1) {
      // Se la parola viene trovata, la elimino
      state.words.splice(index, 1);
    }
    var wordsList = global.vm.lodash.filter(state.words, w => {
      var days = diff(w.timestamp);
      return days < global.config.dayWordsSaved;
    });
    state.words = global.vm.lodash.orderBy(wordsList, ["counter"], ["desc"]);
  },
  [DELETE_ALL_WORDS](state) {
    state.words = [];
  },
  [ADD_FAST_SEARCH](state, item) {
    state.fastSearchList.push(item);
  },
  [REMOVE_FAST_SEARCH](state, item) {
    var index = global.vm.lodash.findIndex(state.fastSearchList, function(w) {
      return w.toLowerCase() === item.toLowerCase();
    });
    if (index !== -1) {
      // Se la parola viene trovata, la elimino
      state.fastSearchList.splice(index, 1);
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
