<template>
  <v-app-bar
    app
    color="white"
    prominent
    clipped-left
    dense
    elevation="3"
    hide-on-scroll
    :class="classCordova"
    :height="77"
    class="pa-0"
    extension-height="160"
  >
    <v-container
      fluid
      class="mx-auto pa-0 fill-height d-flex align-center justify-space-between"
    >
      <router-link
        to="/"
        class="d-flex justify-start align-center flex-row text-decoration-none"
      >
        <img
          src="@/assets/GS1_Italy_CMYK.svg"
          :width="73"
          eager
          alt="Logo"
          @click="extended = false"
        />
        <h4 class="spacing">One</h4>
      </router-link>
      <div class="global-nav"></div>
    </v-container>
    <div
      v-if="$vuetify.breakpoint.smAndDown"
      class="pa-0 fill-height d-flex align-center"
    >
      <v-btn @click.prevent.stop="extended = !extended" icon large>
        <v-icon v-if="!extended" color="primary" large>mdi-menu</v-icon>
        <v-icon v-else color="primary" large>mdi-close</v-icon>
      </v-btn>
    </div>
    <div v-else class="pa-0 fill-height d-flex align-center flex-nowrap">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        offset-y
        min-width="800"
        style="height:80vh"
        class="test"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">{{
            $t("navbar.navigation.allProducts")
          }}</v-btn>
        </template>
        <v-card class="white category-tree-menu">
          <MasonryCategoryMenu @close="menu = false" />
        </v-card>
      </v-menu>
      <v-btn
        text
        v-for="(link, index) in url"
        v-bind:key="index"
        @click="navigate(link)"
        >{{ $t(link.title) }}</v-btn
      >

      <!-- <v-divider vertical></v-divider> -->
      <v-btn
        v-if="!isAuthenticated"
        @click.stop="handleLogin()"
        elevation="0"
        text
      >
        {{ $t("navbar.navigation.cart") }}
      </v-btn>
      <v-badge
        v-else
        color="secondary"
        :content="cartItems"
        :value="cartItems"
        overlap
      >
        <v-btn
          @click.stop="$router.push('/cart').catch(err => err)"
          elevation="0"
          text
        >
          {{ $t("navbar.navigation.cart") }}
        </v-btn>
      </v-badge>
      <v-btn
        v-if="!isAuthenticated"
        @click.stop="handleLogin()"
        elevation="0"
        text
      >
        <v-icon left>
          mdi-account-circle
        </v-icon>
        {{ $t("navbar.navigation.profile") }}
      </v-btn>
      <v-btn
        v-else
        @click.stop="$router.push('/profile/profile-update').catch(err => err)"
        elevation="0"
        text
        :loading="profileLoadig"
      >
        <v-icon left>
          mdi-account-circle
        </v-icon>
        {{ user.lastName }}
      </v-btn>
    </div>
    <template v-slot:extension v-if="extended">
      <v-list style="width:100%" class="py-0">
        <v-list-item
          v-for="(link, index) in url"
          v-bind:key="index"
          @click="navigate(link)"
          class="primary--text font-weight-medium mx-n4"
        >
          <v-list-item-content>
            <v-list-item-title
              v-text="$t(link.title)"
            ></v-list-item-title> </v-list-item-content
        ></v-list-item>
        <v-list-item
          v-if="isAuthenticated"
          class="primary--text font-weight-medium mx-n4"
          @click.stop="navigate({ url: '/cart' })"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("navbar.navigation.cart") }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="primary lighten-2 primary--text font-weight-medium mx-n4 mb-n4"
          @click.stop="openProfile"
        >
          <v-list-item-content>
            <v-list-item-title
              ><v-icon left color="primary">
                mdi-account-circle
              </v-icon>
              {{
                isAuthenticated
                  ? user.lastName
                  : $t("navbar.navigation.profile")
              }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <v-progress-linear
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
  </v-app-bar>
</template>

<script>
import FastSearchMenu from "@/components/navigation/FastSearchMenu.vue";
import MasonryCategoryMenu from "@/components/navigation/MasonryCategoryMenu";
//import UserService from "~/service/userService";
//import SecurityService from "@/commons/service/securityService";

import login from "~/mixins/login";
import clickHandler from "~/mixins/clickHandler";
import cartInfo from "~/mixins/cartInfo";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Navbar",
  props: ["links", "user"],
  mixins: [login, clickHandler, cartInfo],
  components: {
    MasonryCategoryMenu
    //SearchInput,
    //MenuNavigator
    // MasonryCategoryMenu
  },
  data() {
    return {
      menu: false,
      isMobile: false,
      extended: false,
      profileLoadig: false,
      url: [
        { title: "navbar.navigation.advacedSearch", url: "/advanced-search" },
        { title: "navbar.navigation.browse", url: "/category" }
      ]
    };
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight"
    }),
    goBack() {
      this.$router.go(-1);
    },
    goToLists() {
      this.$router.push({ path: "profile/lists" });
    },
    async openTimeSlotSelector() {
      await this.setTimeslot();
    },
    navigate(link) {
      this.$router.push(link.url).catch(err => err);
      this.extended = false;
    },
    openProfile() {
      this.isAuthenticated
        ? this.$router.push("/profile/profile-update").catch(err => err)
        : this.handleLogin();
      this.extended = false;
    },
    openFastSearchDialog() {
      var defaultConfig = {
        toolbarColor: "secondary"
      };
      let properties = {
        waitForResult: true,
        width: 750
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = this.$dialog.show(FastSearchMenu, config);
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      await this.needLogin("navbar");
      // const loginRedirectUrl = await SecurityService.externalLogin();
      // window.location.replace(loginRedirectUrl);
    },
    historyBack() {
      window.history.back();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      showOverlay: "app/showOverlay",
      cartItems: "cart/getItemsCount"
    }),
    totalItems() {
      return this.cart.totalItems;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  }
  // async mounted() {
  //   let that = this;
  //   this.profileLoadig = true;
  //   if (this.isAuthenticated) {
  //     try {
  //       let res = await UserService.getUserDetail();
  //       if (res && !res.errors) {
  //         this.billingData = res;
  //       } else {
  //         if (res.errors) {
  //           that.error = true;
  //         }
  //       }
  //     } catch (response) {
  //       this.response = response;
  //     } finally {
  //       this.profileLoadig = false;
  //       this.name = this.billingData.lastName;
  //     }
  //   }
  // }
  // beforeDestroy() {
  //   if (typeof window === "undefined") return;
  //   window.removeEventListener("resize", this.onResize, { passive: true });
  // },
  // async mounted() {
  //   var _this = this;
  //   setTimeout(function() {
  //     _this.onResize();
  //     window.addEventListener("resize", _this.onResize, { passive: true });
  //   }, 500);
  //   setTimeout(function() {
  //     _this.onResize();
  //   }, 3000);
  // }
};
</script>
<style scoped>
.spacing {
  margin-left: 14px;
  padding-left: 0px;
}
</style>
