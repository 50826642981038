import { mapActions } from "vuex";
import pushNotification from "~/mixins/pushNotification";
export default {
  mixins: [pushNotification],
  methods: {
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      let data = await this.logout();
      console.log("data", data);
      window.location.replace(data.loginRedirectUrl);
      if (global.config.pushNotificationEnabled) {
        // DOPO IL LOGOUT VIENE FATTO UNREGISTER DA FIREBASE
        this.unRegisterFirebaseDevice();
      }
    }
  }
};
