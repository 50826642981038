<template>
  <div class="company-select">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
    <div
      class="custom-select"
      v-if="companyOptions && companyOptions.length > 0"
    >
      <v-select
        :label="$t('navbar.selectCompany')"
        outlined
        dense
        solo
        flat
        append-icon="mdi-chevron-down"
        class="ps-4 pt-0"
        color="secondary darken-1"
        hide-details
        :items="companyOptions"
        item-text="company_name"
        item-value="company_id"
        v-model="company"
        @change="handleSelect"
      />
    </div>
    <div
      v-else-if="errorMessage"
      class="d-flex flex-column align-center justify-space-around h-100"
    >
      <h2 class="errorMessage">
        {{ errorMessage.message }}
      </h2>
      <v-btn color="primary" @click.stop="doLogout()">{{
        $t("navbar.navigation.logout")
      }}</v-btn>
    </div>
  </div>
</template>
<script>
import CompanyService from "@/commons/service/companyService";
import { mapGetters, mapActions } from "vuex";
import logout from "@/commons/mixins/logout";
export default {
  name: "CompanySelect",
  mixins: [logout],
  data() {
    return {
      company: null,
      companyOptions: [],
      loading: false,
      errorMessage: null
    };
  },
  methods: {
    ...mapActions({
      loadCart: "cart/loadCart",
      reload: "category/loadCategoryTree",
      setImages: "cart/hasImagesInContract"
    }),
    async handleSelect() {
      this.loading = true;
      await CompanyService.chageCompanies(this.company);
      await this.loadCart();
      await this.reload();
      this.loading = false;
      this.$router.push({ name: "Home" });
      this.$emit("submit", this.company);
    }
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      isAuthenticated: "cart/isAuthenticated"
    })
  },
  async mounted() {
    if (this.isAuthenticated) {
      this.setImages();
      this.loading = true;
      await CompanyService.getCompanies(this.user.codInt).then(c => {
        this.companyOptions = c.data.company_info;
        this.errorMessage = c.response.errorsMessage[0];
      });
      this.loading = false;
      if (this.companyOptions && this.companyOptions.length == 1) {
        this.$router.push({ name: "Home" });
        this.$emit("submit");
      }
    }
  }
};
</script>
<style lang="scss">
.company-select {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  .custom-select {
    width: 80%;
  }
  .ps-4 {
    padding: 0px !important;
  }
  .errorMessage {
    color: (var(--v-error-base));
    text-align: center;
  }
}
</style>
