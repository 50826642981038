<template>
  <v-card
    flat
    v-if="cart.cartId > 0"
    class="grey lighten-3"
    :class="{ docked: docked }"
  >
    <v-card-text class="pa-3 text-right">
      <div>
        {{ $t("cart.checkoutSummary.itemsTotal") }}
        {{ $n(cart.totalPrice - cart.taxTotal, "currency") }}
      </div>
      <div>
        {{ $t("cart.checkoutSummary.taxTotal") }}
        {{ $n(cart.taxTotal, "currency") }}
      </div>
      <div v-if="full">
        <div
          v-for="gift in cart.giftCertificates"
          :key="gift.giftCertificateId"
          class="d-flex justify-space-between align-center"
        >
          <span class="font-weight-bold">{{ gift.description }} pane</span>
          <v-btn icon @click="removeCode(gift.giftCertificateId)">
            <v-icon color="grey lighten-1">mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="text-h6" v-if="full">
        {{ $t("cart.checkoutSummary.packageTotal") }}
        {{ $n(cart.packageTotal, "currency") }}
      </div>
      <div v-if="full">
        <div
          v-for="orderPackage in cart.orderPackages"
          :key="orderPackage.orderPackageId"
        >
          {{ orderPackage.package.descr }} ({{ orderPackage.quantity }}x{{
            $n(orderPackage.package.cost, "currency")
          }})
          {{ $n(orderPackage.totalPrice, "currency") }}
        </div>

        <div
          v-for="gift in cart.giftCertificatesToGenerate"
          :key="gift.gigftCertificateId"
        >
          <span> {{ gift.description }} pane </span>
          <span v-if="gift.giftTotal">
            {{ $n(gift.giftTotal, "currency") }}
          </span>
        </div>
      </div>
      <div class="text-h4 font-weight-bold">
        {{ $t("cart.checkoutSummary.total") }}
        {{ $n(cart.totalPrice, "currency") }}
      </div>
    </v-card-text>
    <v-card-actions
      class="d-flex flex-row justify-space-between"
      v-if="cart.totalItems > 0"
    >
      <v-btn
        outlined
        text
        color="primary"
        v-on:click="closeDrawer"
        v-if="!full"
        >{{ $t("cart.button.continue") }}</v-btn
      >
      <v-btn to="/checkout" color="secondary" elevation="0">{{
        $t("cart.button.showCart")
      }}</v-btn>
      <v-btn to="/checkout" color="secondary" elevation="0">{{
        $t("cart.button.goToPayment")
      }}</v-btn>
    </v-card-actions>
  </v-card>
  <v-card v-else>{{ $t("no-cart") }}</v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "CartSummary",
  props: ["full", "docked"],
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart"
    }),
    ...mapActions({
      removeGiftCode: "cart/removeGiftCode",
      updatedDrawerRight: "app/updatedDrawerRight"
    }),
    closeDrawer() {
      this.updatedDrawerRight(false);
    },
    removeCode(giftCodeId) {
      this.removeGiftCode(giftCodeId);
    },
    changeService() {},
    changeTimeslot() {},
    changeDelivery() {}
  }
};
</script>

<style scoped lang="scss"></style>
