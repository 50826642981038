import Vue from "vue";
import Toasted from "vue-toasted";
import router from "@/router";

Vue.use(Toasted);

// you can also pass options, check options reference below
Vue.use(Toasted, {
  router,
  position: "bottom-center",
  duration: 3000,
  iconPack: "mdi",
  theme: "bubble",
  keepOnHover: true,
  action: {
    icon: "mdi-close",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  }
});
