import axios from "axios";
import "../config";
import store from "../store";
import Qs from "qs";
import { setupCache } from "axios-cache-adapter";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  limit: 50,
  clearOnStale: true,
  readOnError: true,
  exclude: {
    query: false,
    // paths: ["^/(?!ebsn/api/products)(.*)", "^/(?!ebsn/api/category)(.*)"]
    filter: function(path) {
      let include =
        path.url.indexOf("/ebsn/api/products") === 0 ||
        path.url.indexOf("/ebsn/api/category") === 0 ||
        path.url.indexOf("/ebsn/api/adv") === 0;
      return !include;
    }
  }
});

console.log(process.env.VUE_APP_ROUTER_MODE);
const axiosInstance = axios.create({
  // baseURL:
  //   process.env.VUE_APP_ROUTER_MODE === "hash"
  //     ? process.env.VUE_APP_EBSN_URL
  //     : null,
  paramsSerializer: function(params) {
    return Qs.stringify(params, { arrayFormat: "repeat" });
  },
  adapter: cache.adapter
});

axiosInstance.options.crossOrigin = true;

function showResponse(response, level, field) {
  if (Array.isArray(response)) {
    for (let i = 0; i < response.length; i++) {
      global.EventBus.$emit(level, {
        message: response[i][field].replace(/<[^>]+>/gm)
      });
    }
  } else {
    global.EventBus.$emit(level, {
      message: response
    });
  }
}

function setSessionHeader(response) {
  if (response && response.headers && (response.headers["X-Ebsn-Session"] || response.headers["x-ebsn-session"])) {
    let session;
    if (response.headers["X-Ebsn-Session"]) {
		session = response.headers["X-Ebsn-Session"];
	} else {
		session = response.headers["x-ebsn-session"];
	}
    window.localStorage.setItem("X-Ebsn-Session", session);
  }
}

function toggleProgressLoader(show) {
  store.commit(
    show ? "app/increasePendingRequests" : "app/decreasePendingRequests"
  );
}

function setWithExpiry(key, ttl) {
  const now = new Date();

  window.localStorage.setItem(key, now.getTime() + ttl);
}

function getWithExpiry(key) {
  const item = window.localStorage.getItem(key);

  // if the item doesn't exist
  if (!item) {
    return true;
  }

  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item) {
    // If the item is expired, delete the item from storage
    window.localStorage.removeItem(key);
    return true;
  }
  return false;
}

axiosInstance.interceptors.request.use(request => {
  if (request.url.indexOf("maps.googleapis.com") < 0) {
    request.headers["X-Ebsn-Client"] = process.env.VUE_APP_CLIENT;
    request.headers["X-Ebsn-Version"] = process.env.VUE_APP_VERSION;
    if (window.localStorage.getItem("X-Ebsn-Session")) {
      let session = window.localStorage.getItem("X-Ebsn-Session");
      request.headers["X-Ebsn-Session"] = session;
    }
  }
  toggleProgressLoader(true);
  return request;
});

axiosInstance.interceptors.response.use(
  response => {
    toggleProgressLoader(false);
    setSessionHeader(response);
    if (response && response.data && response.data.response) {
      //check per caso specifico delete address
      if (response.config.url.indexOf("/ebsn/api/user-address/delete") > -1) {
        if (
          response.data.response.infosMessage.length > 0 &&
          response.data.response.infos.length > 0
        ) {
          if (
            response.data.response.infosMessage[0].code
              .toLowerCase()
              .indexOf("error") > -1
          ) {
            response.data.response.infos[0].info =
              "Non è possibile eliminare l'indirizzo selezionato per la consegna della spesa.";
            showResponse(response.data.response.infos, "error", "info");
            response.data.response.infos = [];
            response.data.response.infosMessage = [];
          }
        } else {
          if (
            response.data.response.errorsMessage.length > 0 &&
            response.data.response.errors.length > 0
          ) {
            response.data.response.errors[0].error =
              "Non è possibile eliminare l'indirizzo selezionato per la consegna della spesa.";
          }
        }
      }
      showResponse(response.data.response.infos, "success", "info");
      showResponse(response.data.response.errors, "error", "error");
    }
    return response;
  },
  error => {
    toggleProgressLoader(false);

    if (error && error.response) {
      setSessionHeader(error.response);
    }
    if (
      error.response &&
      error.response.status >= 500 &&
      error.response.status < 599
    ) {
      global.vm.$router.push(
        {
          path: "/service-down"
        },
        () => {}
      );
    } else if (error.response && error.response.status === 404) {
      global.vm.$router.push(
        {
          path: "/not-found"
        },
        () => {}
      );
    } else {
      if (error.response && 419 === error.response.status) {
        //its not an error
        //showResponse(error.response.data.response.infos, "success", "info");
        global.EventBus.$emit("isNotLoggedIn");
        if (getWithExpiry("notAuthorized")) {
          setWithExpiry("notAuthorized", 3000);
          showResponse(
            "La sessione è scaduta, si prega di rieffettuare il login per continuare.",
            "error"
          );
        }
        if (window.localStorage.getItem("X-Ebsn-Session")) {
          window.localStorage.removeItem("X-Ebsn-Session");
        }
        if (window.localStorage.getItem("X-Ebsn-Account")) {
          window.localStorage.removeItem("X-Ebsn-Account");
        }
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.response
      ) {
        showResponse(error.response.data.response.infos, "success", "info");
        showResponse(error.response.data.response.errors, "error", "error");
      }
      //fixare questa cosa, non va bene tornare il messaggio di axios.
      if (
        error.response &&
        error.response.data &&
        error.response.data.response
      ) {
        if (error.response.data.response.errors.length == 0) {
          error.response.data.response.errors.push({
            error: "Si è verificato un errore, riprova tra qualche minuto."
          });
        }
        return Promise.reject(error.response.data.response);
      } else {
        return Promise.reject({
          status: 1,
          errors: {
            error: "Si è verificato un errore, riprova tra qualche minuto."
          }
        });
      }
    }
  }
);

export default axiosInstance;
