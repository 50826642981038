<template>
  <v-container class="address-selector fill-height mt-1">
    <v-layout fill-height column>
      <h3
        class="title font-weight-bold"
        v-if="category && category.metaData && category.metaData.category_info"
      >
        {{ category.metaData.category_info.TITLE }}
      </h3>
      <p
        class="selector-subitle"
        v-html="category.metaData.category_info.DESCRIPTION"
        v-if="
          category &&
            category.metaData &&
            category.metaData.category_info &&
            category.metaData.category_info.DESCRIPTION
        "
      ></p>
      <!-- <v-divider></v-divider> -->
      <!-- v-model="deliveryService" -->
      <v-row
        :height="$vuetify.breakpoint.smAndDown ? 100 : 110"
        class="mb-7 align-center justify-space-between"
      >
        <!-- <v-tabs-slider></v-tabs-slider> -->
        <v-col
          v-for="service in deliveryServices"
          :key="service.deliveryServiceId"
          @click="fetchAddresses(service)"
          v-model="tab"
          class="d-flex align-center"
          cols="12"
          :sm="deliveryServiceButtonsLength"
        >
          <v-spacer></v-spacer>
          <div
            class="address-div d-flex flex-column align-center justify-center flex-grow-1 text-center"
            :class="{
              selected:
                deliveryService.deliveryServiceId === service.deliveryServiceId
            }"
          >
            <img
              class="mb-2"
              height="50"
              width="70"
              :src="getServiceImg(service.deliveryServiceId)"
            />
            <span
              class="primary--text--lighten-5"
              :class="{
                selected:
                  deliveryService.deliveryServiceId ===
                  service.deliveryServiceId
              }"
              >{{ service.name }}</span
            >
          </div>
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <!-- <v-divider></v-divider> -->
      <div class="service-description" v-html="deliveryService.descr"></div>
      <v-card :loading="loading" flat>
        <!-- <v-row>
          <v-col
            cols="12"
            sm="4"
            v-if="deliveryService.deliveryServiceId === 2"
          >
            <v-btn
              color="primary"
              large
              depressed
              @click="openAddAddress()"
              class="add-address"
            >
              AGGIUNGI INDIRIZZO
            </v-btn>
          </v-col>
          <v-col
            :cols="12"
            :sm="deliveryService.deliveryServiceId === 2 ? 8 : 12"
          >
            <v-text-field
              class="searchbar"
              hide-details
              v-model="searchText"
              clearable
              outlined
              flat
              :label="
                $t(
                  'navbar.service.' + selectedDeliveryServiceId + '.searchLabel'
                )
              "
            >
              <v-icon slot="append" color="red">
                $search
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row> -->
        <v-list dense class="address-list">
          <v-list-item-group v-model="shippingAddress" color="primary">
            <v-list-item
              two-line
              v-for="(address, index) in filteredAddresses"
              :key="address.addressId"
              @click="setAddress(address)"
            >
              <v-list-item-avatar>
                <!-- v-if="cart.shippingAddress.addressId == address.addressId" -->
                <v-icon v-if="shippingAddress == index"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="align-self-start">
                <v-list-item-title class="font-weight-bold">
                  {{ address.addressName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    $t(
                      "navbar.address." + address.addressTypeId + ".list",
                      address
                    )
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="flex-row align-center">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="
                        address.addressId == cart.user.defaultStoreAddressId ||
                          address.addressId ==
                            cart.user.defaultDeliveryAddressId
                      "
                      >mdi-star-outline</v-icon
                    >
                  </template>
                  <span>{{
                    address.addressType == "home"
                      ? "Indirizzo preferito"
                      : "Negozio preferito"
                  }}</span>
                </v-tooltip>
                <!-- <v-btn
                  icon
                  @click.stop="edit(address)"
                  v-if="address.addressType == 'home'"
                >
                  <v-icon>$edit</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click.stop="remove(address)"
                  v-if="address.addressType == 'home'"
                >
                  <v-icon>$delete</v-icon>
                </v-btn> -->
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src
import StoreService from "~/service/storeService";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import { mapState } from "vuex";
var vm;
export default {
  name: "AddressSelector",
  data() {
    return {
      store: {},
      addresses: [],
      loading: null,
      shippingAddress: null,
      deliveryService: null,
      tab: 2,
      searchText: null,
      category: {}
    };
  },
  computed: {
    filteredAddresses() {
      if (this.searchText) {
        return this.addresses.filter(address => {
          return (
            address.addressName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.address1
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.city.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1
          );
        });
      } else {
        return this.addresses;
      }
    },
    deliveryServiceButtonsLength() {
      console.log(12 / this.deliveryServices.length);
      return 12 / this.deliveryServices.length;
    },
    ...mapState({
      cart: ({ cart }) => cart.cart,
      selectedService: ({ cart }) => cart.selectedService
    }),
    selectedDeliveryServiceId() {
      return this.deliveryService ? this.deliveryService.deliveryServiceId : 0;
    },
    deliveryServices() {
      if (this.store && this.store.deliveryServices) {
        return this.store.deliveryServices.filter(button => {
          return button.showTab;
        });
      }
      return [];
    }
  },
  methods: {
    async remove(address) {
      const res = await vm.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        let response = await DeliveryService.removeAddress(address.addressId);
        vm.addresses = response.addresses;
      }
    },
    edit(address) {
      // _this.address = address;
      vm.$router.push({
        path: "/profile/addresses/manage/" + address.addressId
      });
      vm.$emit("submit", true);
    },
    async fetchStore() {
      let store = await StoreService.getStoreById(vm.cart.store.storeId);
      vm.store = store;

      vm.store.deliveryServices.find(deliveryService => {
        if (
          deliveryService.deliveryServiceId ==
          vm.cart.shippingAddress.deliveryServiceId
        ) {
          vm.deliveryService = deliveryService;
        }
      });
    },
    async fetchAddresses(deliveryService) {
      vm.loading = true;
      vm.searchText = null;
      let deliveryServiceId =
        deliveryService.deliveryServiceId || deliveryService;
      vm.deliveryService = deliveryService;
      vm.addresses = await StoreService.getAddressesByDeliveryService(
        deliveryServiceId
      );

      vm.addresses.find((address, index) => {
        if (address.addressId == vm.cart.shippingAddress.addressId) {
          vm.shippingAddress = index;
        }
      });
      vm.loading = false;
    },
    async setAddress(address) {
      this.loading = true;
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId
      });

      this.loading = false;
      if (data) {
        vm.$emit("submit", true);
      }
    },
    getServiceImg(serviceId) {
      return this.$t("navbar.service." + serviceId + ".icon");
    },
    openAddAddress() {
      this.$router.push({
        path: "/profile/addresses/manage"
      });
      this.$emit("submit", false);
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "service-selector"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    vm = this;
    this.fetchStore();
    this.fetchAddresses(this.cart.shippingAddress.deliveryServiceId);
    this.fetchCategory();
  }
};
</script>
