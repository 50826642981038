const ADD_PRODUCT = "ADD_PRODUCT";
const REMOVE_PRODUCT = "REMOVE_PRODUCT";
const SET_FILTER_SORT = "SET_FILTER_SORT";
const SET_FILTER_VIEW = "SET_FILTER_VIEW";

const state = {
  cart: [],
  filters: {
    sort: "alf_asc",
    view: "small-grid"
  }
};

const getters = {
  getCartItems: state => {
    return state.cart;
  },
  getItemsCount: state => {
    if (state.cart) {
      return state.cart.length;
    } else return 0;
  },
  sortFilter: state => {
    return state.filters.sort;
  },
  viewFilter: state => {
    return state.filters.view;
  },
  sortedCart: state => {
    const cart = state.cart.slice();
    if (state.filters.sort == "alf_asc") {
      cart.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else {
      cart.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }
    return cart;
  }
};

const actions = {
  async addProduct({ commit }, params) {
    commit(ADD_PRODUCT, params);
  },
  async removeProduct({ commit, state }, params) {
    const i = state.cart.map(item => item.productId).indexOf(params.productId);
    commit(REMOVE_PRODUCT, i);
  },
  setFilterSort({ commit }, sort) {
    commit(SET_FILTER_SORT, sort);
  },
  setFilterView({ commit }, view) {
    commit(SET_FILTER_VIEW, view);
  }
};

const mutations = {
  [ADD_PRODUCT](state, value) {
    state.cart.push(value);
  },
  [REMOVE_PRODUCT](state, value) {
    state.cart.splice(value, 1);
  },
  [SET_FILTER_SORT](state, value) {
    state.filters.sort = value;
  },
  [SET_FILTER_VIEW](state, value) {
    state.filters.view = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
