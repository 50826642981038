//const SET_PRODUCTS = "SET_PRODUCTS";
const ADD_PRODUCT = "ADD_PRODUCT";
const ADD_PRODUCTS = "ADD_PRODUCTS";
const REMOVE_PRODUCT = "REMOVE_PRODUCT";
const REMOVE_ALL_PRODUCT = "REMOVE_ALL_PRODUCT";

const state = {
  products: []
};
const getters = {
  getProducts: state => {
    return state.products;
  }
};
const actions = {
  addProduct({ commit }, params) {
    commit(ADD_PRODUCT, params);
  },
  addProducts({ commit }, params) {
    commit(ADD_PRODUCTS, params);
  },
  removeProduct({ commit, state }, params) {
    const i = state.products
      .map(item => item.productId)
      .indexOf(params.productId);
    commit(REMOVE_PRODUCT, i);
  },
  removeAllProducts({ commit }) {
    commit(REMOVE_ALL_PRODUCT);
  }
};
const mutations = {
  [ADD_PRODUCT](state, value) {
    state.products.push(value);
  },
  [ADD_PRODUCTS](state, value) {
    state.products = value;
  },
  [REMOVE_PRODUCT](state, value) {
    state.products.splice(value, 1);
  },
  [REMOVE_ALL_PRODUCT](state) {
    state.products = [];
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
