<template>
  <v-list flat subheader dense class="py-0">
    <v-list-item class="primary">
      <v-list-item-content>
        <v-list-item-title v-if="!isCart">
          <span>{{ serviceName }}</span>
        </v-list-item-title>
        <v-list-item-subtitle
          class="white--text text-body-2 d-flex flex-column align-left"
        >
          <v-row no-gutters class="d-flex align-center">
            <span class="row-label">
              {{ $t("checkout.deliveryService.serviceSelected") }}
            </span>

            <v-btn
              color="white"
              class="text-body-2 font-weight-bold"
              :disabled="!editableAddress"
              text
              tile
              small
              height="27"
              elevation="0"
              rounded
              @click="openAddressSelector"
            >
              <!-- <img class="ml-0 ml-sm-2" :src="serviceImg" /> -->
              <span class=" ml-2 text-capitalize">{{ serviceName }}</span>
              <span v-if="editableAddress" class="ml-2"
                >({{ $t("cart.cartInfoList.edit") }})</span
              >
            </v-btn>
          </v-row>

          <v-row no-gutters class="d-flex align-center">
            <span>
              {{
                $t(
                  `checkout.deliveryService.addressSelected1.${cart.shippingAddress.deliveryServiceId}`
                )
              }}
            </span>

            <v-btn
              color="white"
              :disabled="!editableAddress"
              text
              tile
              small
              class="text-body-2 font-weight-bold"
              elevation="0"
              rounded
              @click="openAddressSelector"
            >
              <span class="ml-0 ml-sm-2">{{ shippingAddress }}</span>
              <span v-if="editableAddress" class="ml-2"
                >({{ $t("cart.cartInfoList.edit") }})</span
              >
            </v-btn>
          </v-row>

          <v-row no-gutters class="d-flex align-center">
            <span>
              {{ $t("checkout.deliveryService.timeslotSelected") }}
            </span>
            <v-btn
              color="white"
              :disabled="!editableTimeslot"
              text
              tile
              small
              elevation="0"
              class="text-body-2 font-weight-bold"
              rounded
              @click="openTimeslotSelector"
            >
              <span>{{ timeslot }}</span>
              <span v-if="editableTimeslot" class="ml-2"
                >({{ $t("cart.cartInfoList.edit") }})</span
              >
            </v-btn>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
export default {
  name: "CartInfoList",
  mixins: [login, cartInfo],
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  props: { isCart: { type: Boolean, required: false } }
};
</script>
