<template>
  <div class="fill-height mt-1">
    <v-container fluid fill-height>
      <v-layout>
        <v-flex xs12 mt-5>
          <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
            <v-card class="elevation-0">
              <div class="d-flex align-center flex-column mb-3">
                <h1 class="primary--text">{{ $t("login.title") }}</h1>
                <h3 class="font-weight-regular">
                  {{ $t("login.subtitle") }}
                </h3>
              </div>
              <v-spacer></v-spacer>
              <v-card-text>
                <!-- <div class="d-flex align-center justify-space-between">
                  <v-checkbox
                    color="primary"
                    class="ma-0"
                    hide-details
                    v-model="rememberMe"
                  >
                    <template v-slot:label>
                      <span>{{ $t("login.rememberMe") }}</span>
                    </template>
                  </v-checkbox>
                </div> -->

                <!-- <ResponseMessage :response="response" /> -->
                <v-row no-gutters justify="center" class="mt-6">
                  <v-col cols="12">
                    <v-btn
                      :loading="loading"
                      type="submit"
                      class="primary"
                      large
                      text
                      width="100%"
                    >
                      {{ $t("login.buttonLogin") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- <v-divider></v-divider> -->
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
//import ResponseMessage from "@/components/ResponseMessage";

import pushNotification from "~/mixins/pushNotification";
import SecurityService from "@/commons/service/securityService";

import form from "~/mixins/form";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ExternalLogin",
  props: {
    hideRegistration: { type: Boolean, required: false, default: false }
  },
  mixins: [form, pushNotification],
  // components: { ResponseMessage },
  data() {
    return {
      loading: null,
      showPassword: false,
      rememberMe: false,
      response: {},
      valid: true,
      lazy: true,
      errors: [],
      invalid: null
    };
  },
  computed: {},
  methods: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart"
    }),
    async handleSubmit(e) {
      e.preventDefault();
      // if (this.password.length > 0) {
      //   try {
      //     this.loading = true;
      //     this.response = {};
      //     await this.doLogin({
      //       email: this.email,
      //       password: this.password,
      //       rememberMe: this.rememberMe
      //     });
      //     var _this = this;
      //     // SE AUTENTICATO REGISTRA IL DEVICE PER PUSH NOTIFICATION
      //     if (_this.isAuthenticated && global.config.pushNotificationEnabled) {
      //       console.log("IS AUTHENTICATED AND PUSH ENABLED");
      //       this.registerFirebaseDevice();
      //     }
      //     this.$emit("submit", true);
      //   } catch (response) {
      //     this.response = response;
      //   } finally {
      //     this.loading = false;
      //   }
      // }
      const loginRedirectUrl = await SecurityService.externalLogin();
      window.location.replace(loginRedirectUrl);
    }
  }
};
</script>
