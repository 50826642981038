<template>
  <v-dialog
    ref="vdialog"
    eager
    :contentClass="extraClass ? extraClass + ' vuedl-layout' : 'vuedl-layout'"
    v-model="isActive"
    :fullscreen="fullscreen"
    :max-width="getWidth"
    :persistent="persistent || loading"
    :scrollable="scrollable"
    :transition="transition"
    :hide-overlay="hideOverlay"
    :retain-focus="false"
    @keydown.esc="dismiss"
  >
    <v-card>
      <!-- color="primary" -->
      <v-toolbar dark :color="toolbarColor" elevation="0" height="80px">
        <v-spacer></v-spacer>
        <v-toolbar-title
          v-if="!hideToolbarLogo"
          class="mx-auto pa-0 fill-height d-flex align-center justify-space-between"
          ><img
            src="@/assets/GS1_Italy_CMYK.svg"
            :width="73"
            eager
            alt="Logo"
          />
          <h4 v-if="!$vuetify.breakpoint.xs" class="pl-4 primary--text">
            One
          </h4></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            v-if="showClose && !persistent && !loading"
            icon
            depressed
            dark
            @click.stop="close"
          >
            <v-icon color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <dialog-child ref="dialog" v-bind="$options.propsData" />
    </v-card>
  </v-dialog>
</template>

<script>
import { VDialog } from "vuetify/lib";

export default {
  components: {
    VDialog
  },
  props: {
    fullscreen: Boolean,
    scrollable: Boolean,
    hideOverlay: Boolean,
    transition: {
      type: [String, Boolean],
      default: "dialog-transition"
    },
    showClose: {
      type: Boolean,
      default: () => true
    },
    toolbarColor: { type: String, default: "white" },
    hideToolbarLogo: {
      type: Boolean,
      default: () => false
    },
    extraClass: String
  },
  methods: {
    _destroy() {
      // Allow to draw transition, cause vuetify doesn't have onClose method
      setTimeout(() => {
        this.$destroy();
      }, 1000);
      // this.$refs.vdialog.$refs.dialog.addEventListener('transitionend', this.onTransitionEnd)
    }
    // onTransitionEnd (event) {
    //   if (['opacity', 'z-index'].indexOf(event.propertyName) >= 0) {
    //     this.$refs.vdialog.$refs.dialog.removeEventListener('transitionend', this.onTransitionEnd)
    //     this.$destroy()
    //   }
    // }
  }
};
</script>
