import CategoryService from "~/service/categoryService";

const SET_CATEGORY = "SET_CATEGORY";
const SET_CATEGORY_TREE = "SET_CATEGORY_TREE";
const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
const SET_FILTER_FACETS = "SET_FILTER_FACETS";
const SET_FILTER_FACETS_MOBILE = "SET_FILTER_FACETS_MOBILE";
const SET_FILTER_SORT = "SET_FILTER_SORT";
const SET_CATEGORY_SORT = "SET_CATEGORY_SORT";
const SET_FILTER_PROMO = "SET_FILTER_PROMO";
const SET_FILTER_NEW = "SET_FILTER_NEW";
const SET_FILTER_PAGE = "SET_FILTER_PAGE";
const SET_FILTER_CATEGORY_ID = "SET_FILTER_CATEGORY_ID";
const RESET_FILTERS = "RESET_FILTERS";
const SET_PRODUCTS_COUNT = "SET_PRODUCTS_COUNT";
const SET_CATEGORIES_COUNT = "SET_CATEGORIES_COUNT";
const SET_BRANDS_COUNT = "SET_BRANDS_COUNT";
const SET_COMPANY_COUNT = "SET_COMPANY_COUNT";

const state = {
  category: {},
  categoryTree: [],
  categoryList: [],
  categorySort: null,
  count: {
    products: null,
    categories: null,
    brands: null,
    companies: null
  },
  filters: {
    facets: {},
    sort: null,
    promo: false,
    onlyNew: false,
    page: 1,
    categoryId: null
  }
};

const getters = {
  categoryPath: state => {
    const getBreadcrumbItem = function(category) {
      return {
        exact: true,
        link: true,
        text: category.name,
        to: "/category/" + category.slug
      };
    };
    const lookupCategory = function(
      categories,
      categoryId,
      path,
      foundCategory
    ) {
      for (var key in categories) {
        var category = categories[key];
        if (category.categoryId == categoryId) {
          return category;
        } else if (category.categories) {
          var found = lookupCategory(
            category.categories,
            categoryId,
            path,
            foundCategory
          );
          if (found) {
            path.push(getBreadcrumbItem(category));
            return found;
          }
        }
      }
    };
    let path = [];
    let category = lookupCategory(
      state.categoryTree,
      state.category.categoryId,
      path
    );
    //console.log(category);
    if (category) {
      path.push({
        link: true,
        text: "Catalogo",
        to: "/category",
        exact: true
      });
      path = path.reverse();
      path.push(getBreadcrumbItem(category));
    }
    //console.log(path);
    return path;
  },
  facetsFilter: state => {
    return state.filters.facets;
  },
  promoFilter: state => {
    return state.filters.promo;
  },
  newFilter: state => {
    return state.filters.onlyNew;
  },
  sortFilter: state => {
    return state.filters.sort;
  },
  sortCategoryFilter: state => {
    return state.categorySort;
  },
  pageFilter: state => {
    return state.filters.page;
  },
  productsCount: state => {
    return state.count.products;
  },
  brandsCount: state => {
    return state.count.brands;
  },
  categoriesCount: state => {
    return state.count.categories;
  },
  companyCount: state => {
    return state.count.companies;
  }
};

const actions = {
  async setCategory({ commit, state }, { slug, force = false }) {
    try {
      if (
        !state.category ||
        !(state.category.slug === slug || state.category.categoryId === slug) ||
        force === true
      ) {
        let cat = await CategoryService.getCategoryBySlug(slug);
        console.log("setCategory", cat.children, state.categoryList);
        if (cat.categoryTypeId == "1" && cat.children) {
          cat.children = cat.children.filter(el => {
            return state.categoryList.some(f => {
              return f.categoryId === el.categoryId;
            });
          });
        }
        commit(SET_CATEGORY, cat);
        // if (
        //   reset &&
        //   data.data.data.category.categoryId != state.filters.categoryId
        // ) {
        //   commit(SET_FILTER_CATEGORY_ID, data.data.data.category.categoryId);
        //   commit(RESET_FILTERS);
        // }
        return cat;
      } else {
        return state.category;
      }
    } catch (err) {
      // global.EventBus.$emit("error", {
      //   error: err,
      //   message: "errors.errorLoadingCategory"
      // });
      return {};
    }
  },
  async setCategoryNew({ commit, state }, { slug, force = false }) {
    console.log(
      "SET_CATEGORY",
      searchTree({ slug: null, categories: state.categoryTree }, slug)
    );
    if (
      !state.category ||
      !(state.category.slug === slug || state.category.categoryId === slug) ||
      force === true
    ) {
      let cat = searchTree(
        { slug: null, categories: state.categoryTree },
        slug
      );
      commit(SET_CATEGORY, cat);
    }
  },
  loadCategoryTree({ commit, dispatch }) {
    CategoryService.getCategoryTreeSliced()
      .then(data => {
        commit(SET_CATEGORY_TREE, data.categories);
        commit(SET_PRODUCTS_COUNT, data.count_product);
        commit(SET_CATEGORIES_COUNT, data.count_categories);
        commit(SET_BRANDS_COUNT, data.count_brand);
        commit(SET_COMPANY_COUNT, data.count_company);
      })
      .finally(() => {
        dispatch("loadCategoryList");
        dispatch("filterCategoryTree", "alf_asc");
      });
  },
  loadCategoryList({ commit, state }) {
    if (state.categoryTree) {
      const categories = state.categoryTree.slice();
      const flatTree = categories
        .map(category => createFlatTree(category))
        .reduce((a, b) => a.concat(b), []);

      commit(SET_CATEGORY_LIST, flatTree);
    } else commit(SET_CATEGORY_LIST, []);

    function createFlatTree(node) {
      const nodeCopy = { ...node };

      delete nodeCopy.categories;

      let flatTree = [nodeCopy];

      if (node.categories && node.categories.length > 0) {
        return flatTree.concat(
          node.categories

            .map(category => createFlatTree(category))

            .reduce((a, b) => a.concat(b), [])
        );
      }

      return flatTree;
    }
  },
  filterCategoryTree({ commit, state }, sort) {
    commit(SET_CATEGORY_SORT, sort);
    if (state.categoryTree) {
      const categories = state.categoryTree.slice();
      if (sort === "alf_asc") {
        categories.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else {
        categories.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      }
      commit(SET_CATEGORY_TREE, categories);
    }
  },
  setFilterFacets({ commit }, { facets, parameterName }) {
    commit(SET_FILTER_FACETS, { facets, parameterName });
  },
  setFilterFacetsMobile({ commit }, { facets }) {
    commit(SET_FILTER_FACETS_MOBILE, facets);
  },
  setFilterSort({ commit }, sort) {
    commit(SET_FILTER_SORT, sort);
  },
  setCategorySort({ commit }, sort) {
    commit(SET_CATEGORY_SORT, sort);
  },
  setFilterPromo({ commit }, promo) {
    commit(SET_FILTER_PROMO, promo);
  },
  setFilterNew({ commit }, onlyNew) {
    commit(SET_FILTER_NEW, onlyNew);
  },
  setFilterPage({ commit }, page) {
    if (page) {
      commit(SET_FILTER_PAGE, page);
    }
  },

  resetFilters({ commit, state }, categoryId) {
    if (state.filters.categoryId != categoryId) {
      commit(RESET_FILTERS);
      commit(SET_FILTER_CATEGORY_ID, categoryId);
    }
  }
};

const mutations = {
  [SET_CATEGORY](state, value) {
    state.category = value;
  },
  [SET_CATEGORY_TREE](state, value) {
    state.categoryTree = value;
  },
  [SET_CATEGORY_LIST](state, value) {
    state.categoryList = value;
  },
  [SET_FILTER_FACETS](state, { facets, parameterName }) {
    state.filters.facets[parameterName] = facets;
    state.filters.page = 1;
  },
  [SET_FILTER_FACETS_MOBILE](state, facets) {
    state.filters.facets = facets;
    state.filters.page = 1;
  },
  [SET_FILTER_SORT](state, value) {
    state.filters.sort = value;
  },
  [SET_CATEGORY_SORT](state, value) {
    state.categorySort = value;
  },
  [SET_FILTER_PROMO](state, value) {
    state.filters.promo = value;
    state.filters.page = 1;
  },
  [SET_FILTER_NEW](state, value) {
    state.filters.onlyNew = value;
    state.filters.page = 1;
  },
  [SET_FILTER_PAGE](state, value) {
    state.filters.page = value;
  },
  [SET_FILTER_CATEGORY_ID](state, value) {
    state.filters.categoryId = value;
  },
  [RESET_FILTERS](state) {
    state.filters = {
      facets: {},
      sort: null,
      promo: false,
      page: 1
    };
  },
  [SET_PRODUCTS_COUNT](state, value) {
    state.count.products = value;
  },
  [SET_BRANDS_COUNT](state, value) {
    state.count.brands = value;
  },
  [SET_CATEGORIES_COUNT](state, value) {
    state.count.categories = value;
  },
  [SET_COMPANY_COUNT](state, value) {
    state.count.companies = value;
  }
};

function searchTree(tree, categorySlug) {
  if (tree.slug == categorySlug) {
    return tree;
  } else if (tree.categories != null) {
    var i;
    var result = null;
    for (i = 0; result == null && i < tree.categories.length; i++) {
      result = searchTree(tree.categories[i], categorySlug);
    }
    return result;
  }
  return null;
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
