import { handleCategoryNavigation } from "./routeFunctions";

let routes = [
  {
    path: "/reset-password-request",
    name: "ResetPasswordRequest",
    meta: { breadCrumb: "Modifica Password" },
    component: () => import("@/views/profile/ResetPasswordRequest.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("reset-password-request", to, from, next);
    }
  },
  {
    path: "/reset-password-sent",
    name: "ResetPasswordSent",
    component: () => import("@/views/EbsnCategory.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("reset-password-sent", to, from, next);
    }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/profile/ResetPassword.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("reset-password", to, from, next);
    }
  },
  {
    path: "/reset-password-completed",
    name: "ResetPasswordCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    props: { showLogin: true },
    beforeEnter(to, from, next) {
      handleCategoryNavigation("reset-password-completed", to, from, next);
    }
  },
  {
    path: "/registration-user",
    name: "RegistrationUser",
    component: () => import("@/views/profile/RegistrationUser.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("registration-user", to, from, next);
    }
  },
  {
    path: "/registration-completed",
    name: "RegistrationCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("registration-completed", to, from, next);
    }
  },
  {
    path: "/registration/confirm",
    name: "RegistrationUser",
    component: () => import("@/views/profile/RegistrationUser.vue")
  },
  {
    path: "/registration-confirmed",
    name: "RegistrationConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    props: { showLogin: true },
    beforeEnter(to, from, next) {
      handleCategoryNavigation("registration-confirmed", to, from, next);
    }
  },
  {
    path: "/email-confirmed",
    name: "EmailConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    props: { showLogin: true },
    beforeEnter(to, from, next) {
      handleCategoryNavigation("email-confirmed", to, from, next);
    }
  },
  // {
  //   path: "/profile/download-requests",
  //   name: "DownloadRequests",
  //   meta: { requiresAuth: true },
  //   component: () => import("@/views/profile/DownloadRequests.vue")
  // },
  // {
  //   path: "/profile/download-details",
  //   name: "DownloadDetails",
  //   meta: { requiresAuth: true },
  //   component: () => import("@/views/profile/DownloadDetails.vue")
  // },
  {
    path: "/profile/orders",
    name: "AllOrders",
    meta: {
      requiresAuth: true,
      breadCrumb: "I miei ordini"
    },
    props: { ordersAllUsers: false },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    beforeEnter(to, from, next) {
      global.EventBus.$emit("resetFilters");
      next();
    },
    children: [
      {
        name: "MyOrders",
        path: "",
        meta: {
          requiresAuth: true
        },
        component: () => import("@/views/profile/DownloadRequests.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("orders", to, from, next);
        }
      },
      {
        name: "Order",
        path: ":orderId",
        props: { ordersAllUsers: false },
        meta: {
          requiresAuth: true,
          breadCrumb: "Dettaglio ordine"
        },
        component: () => import("@/views/profile/DownloadDetails.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("order", to, from, next);
        }
      }
    ]
  },
  {
    path: "/profile",
    meta: { requiresAuth: true },
    component: () => import("@/views/profile/Profile.vue"),
    children: [
      {
        path: "profile-update",
        name: "ProfileUpdate",
        meta: { requiresAuth: true, breadCrumb: "Il mio profilo" },
        component: () => import("@/views/profile/ProfileUpdate.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("profile", to, from, next);
        }
      },
      {
        path: "profile-update-error",
        name: "ProfileUpdateError",
        meta: { requiresAuth: true, breadCrumb: "Il mio profilo" },
        component: () => import("@/views/profile/ProfileUpdateError.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("profile-error", to, from, next);
        }
      },
      {
        path: "update-password",
        name: "UpdatePassword",
        meta: { requiresAuth: true, breadCrumb: "Aggiorna password" },
        component: () => import("@/views/profile/UpdatePassword.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("update-password", to, from, next);
        }
      },
      {
        path: "profile-confirmed",
        name: "ProfileConfirmed",
        component: () => import("@/views/profile/ProfileConfirmed.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("profile-confirmed", to, from, next);
        }
      },
      {
        path: "profile-updated",
        name: "ProfileUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("profile-updated", to, from, next);
        }
      },
      {
        path: "email-updated",
        name: "EmailUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("email-updated", to, from, next);
        }
      },
      // {
      //   path: "orders",
      //   meta: {
      //     requiresAuth: true,
      //     breadCrumb: "I miei ordini"
      //   },
      //   props: { ordersAllUsers: false },
      //   component: {
      //     render(c) {
      //       return c("router-view");
      //     }
      //   },
      //   beforeEnter(to, from, next) {
      //     global.EventBus.$emit("resetFilters");
      //     next();
      //   },
      //   children: [
      //     {
      //       name: "MyOrders",
      //       path: "",
      //       meta: {
      //         requiresAuth: true
      //       },
      //       component: () => import("@/views/profile/Orders.vue"),
      //       beforeEnter(to, from, next) {
      //         handleCategoryNavigation("orders", to, from, next);
      //       }
      //     },
      //     {
      //       name: "Order",
      //       path: ":orderId",
      //       props: { ordersAllUsers: false },
      //       meta: {
      //         requiresAuth: true,
      //         breadCrumb: "Dettaglio ordine"
      //       },
      //       component: () => import("@/views/profile/Order.vue"),
      //       beforeEnter(to, from, next) {
      //         handleCategoryNavigation("order", to, from, next);
      //       }
      //     }
      //   ]
      // },
      // {
      //   path: "all-orders",
      //   meta: {
      //     requiresAuth: true,
      //     breadCrumb: "Tutti gli ordini"
      //   },
      //   props: true,
      //   component: {
      //     render(c) {
      //       return c("router-view");
      //     }
      //   },
      //   beforeEnter(to, from, next) {
      //     global.EventBus.$emit("resetFilters");
      //     next();
      //   },
      //   children: [
      //     {
      //       name: "AllOrders",
      //       path: "",
      //       props: true,
      //       meta: {
      //         requiresAuth: true
      //       },
      //       component: () => import("@/views/profile/Orders.vue"),
      //       beforeEnter(to, from, next) {
      //         handleCategoryNavigation("all-orders", to, from, next);
      //       }
      //     },
      //     {
      //       name: "Order",
      //       path: ":orderId",
      //       props: { ordersAllUsers: true },
      //       meta: {
      //         requiresAuth: true,
      //         breadCrumb: "Dettaglio ordine"
      //       },
      //       component: () => import("@/views/profile/Order.vue"),
      //       beforeEnter(to, from, next) {
      //         handleCategoryNavigation("order", to, from, next);
      //       }
      //     }
      //   ]
      // },
      {
        path: "lists",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie liste"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Lists",
            path: "",
            meta: {
              requiresAuth: true
            },

            component: () => import("@/views/profile/Lists.vue"),
            beforeEnter(to, from, next) {
              handleCategoryNavigation("lists", to, from, next);
            }
          },
          {
            path: ":listId",
            name: "List",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio lista"
            },
            component: () => import("@/views/profile/Lists.vue")
          }
        ]
      },
      {
        path: "addresses",
        meta: { requiresAuth: true, breadCrumb: "I miei indirizzi" },
        // component: () => import("@/views/ProfileAddresses.vue"),
        // beforeEnter(to, from, next) {
        //   handleCategoryNavigation("addresses", to, from, next);
        // },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Addresses",
            meta: {
              requiresAuth: true
            },
            component: () => import("@/views/profile/ProfileAddresses.vue"),
            beforeEnter(to, from, next) {
              handleCategoryNavigation("addresses", to, from, next);
            }
          },
          {
            path: "manage/:addressId?",
            name: "EditAddresses",
            props: { modal: true },
            meta: { requiresAuth: true, breadCrumb: "Dettaglio indirizzo" },
            component: () => import("@/views/profile/EditAddress.vue")
            // components: {
            //   default: require("@/views/ProfileAddresses.vue").default,
            //   modal: require("@/views/EditAddress.vue").default
            // }
          }
        ]
      },
      {
        path: "paymentduedate",
        name: "PaymentDueDate",
        meta: { requiresAuth: true, breadCrumb: "Le mie scadenze" },
        // component: () => import("@/views/ProfileAddresses.vue"),
        // beforeEnter(to, from, next) {
        //   handleCategoryNavigation("addresses", to, from, next);
        // },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "PaymentDueDate",
            meta: {
              requiresAuth: true
            },
            component: () => import("@/views/profile/PaymentDueDate.vue"),
            beforeEnter(to, from, next) {
              handleCategoryNavigation("duedate", to, from, next);
            }
          }
          // ,
          // {
          //   path: "manage/:addressId?",
          //   name: "EditAddresses",
          //   props: { modal: true },
          //   meta: { requiresAuth: true, breadCrumb: "Dettaglio indirizzo" },
          //   component: () => import("@/views/profile/EditAddress.vue")
          //   // components: {
          //   //   default: require("@/views/ProfileAddresses.vue").default,
          //   //   modal: require("@/views/EditAddress.vue").default
          //   // }
          // }
        ]
      }
    ]
  }
];

export default routes;
