<template>
  <v-container ref="menu" class="pa-0">
    <v-row no-gutters class="d-flex white">
      <v-col cols="5" class="all-products">
        <v-list class="white py-0" flat dense :height="listHeight">
          <v-list-item-group v-model="selectedCategoryFirstLevel">
            <v-list-item
              exact-active-class="red--text"
              v-for="category in categories"
              :key="category.categoryId"
              @click.native.prevent="selectCategory(category)"
            >
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  <router-link
                    class="text-uppercase text-decoration-none font-weight-bold text-body-4 primary--text"
                    :to="{
                      name: 'Category',
                      params: { pathMatch: category.slug }
                    }"
                    event
                    @click.native.prevent="selectCategory(category)"
                  >
                    {{ category.name }}
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="7" v-if="categorySelected">
        <v-list
          dense
          v-for="(category2, index) in categorySelected.categories"
          :key="index"
          class="px-3"
        >
          <v-list-item-title>
            <router-link
              class="text-uppercase text-decoration-none font-weight-bold text-body-2 primary--text"
              :to="{
                name: 'Category',
                params: { pathMatch: category2.slug }
              }"
              link
              :key="category2.categoryId"
              @click.native.prevent="close"
              >{{ category2.name }}
            </router-link>
          </v-list-item-title>
          <v-list-item-group color="primary">
            <v-list-item
              dense
              active-class="link-active"
              class="px-0"
              v-for="category3 in category2.categories"
              :key="category3.categoryId"
              link
              :to="{
                name: 'Category',
                params: { pathMatch: category3.slug }
              }"
              @click="close"
            >
              <v-list-item-content>
                <v-list-item-title v-text="category3.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "MasonryCategoryMenu",
  data() {
    return {
      menu: false,
      selectedCategoryFirstLevel: 0,
      categorySelected: null,
      // DA TESTARE CON I VARI BREAKPOINTS FINO A TABLET "MD".
      masonryCols: { default: 4, 1744: 4, 1264: 3, 960: 2, 400: 1 },
      masonryGutters: {
        default: "3px"
      },
      allCategories: [],
      listHeight: "100%"
    };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    }),
    getCategoriesBySlug() {
      var _this = this;
      var categoryObject = {};
      _this.allCategories = [];
      if (this.categorySelected) {
        this.categories.forEach(element => {
          if (element.categoryId === _this.categorySelected.categoryId) {
            element.categories.forEach(category => {
              categoryObject = category;
              _this.allCategories.push(categoryObject);
            });
          }
        });
      }
      return _this.allCategories;
    }
  },
  methods: {
    ...mapActions({
      reload: "category/loadCategoryTree"
    }),
    onResize() {
      this.listHeight = document.getElementsByClassName(
        "drawer-left"
      )[0].offsetHeight;
    },
    selectCategory(cat) {
      if (cat) {
        this.categorySelected = cat;
      } else {
        this.categorySelected = { categories: this.categories };
      }
    },
    close() {
      this.menu = false;
      this.$emit("close");
    }
  },
  async mounted() {
    window.addEventListener("resize", this.onResize);
    this.reload();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    categories() {
      if (this.categories && this.categories.length) {
        this.selectCategory(this.categories[0]);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.all-products {
  .v-item--active {
    color: $white;
    background-color: var(--v-primary-base);
    font-weight: 700;
    .v-list-item__title {
      font-weight: bold;
    }
    a {
      color: $white !important;
    }
    .v-icon {
      color: $white !important;
    }
  }
  .v-list-item {
    padding-right: 0;
    .v-icon {
      color: var(--v-primary-base);
    }
  }
}
</style>
