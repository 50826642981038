function mapPersonInfo(mapping, personKey) {
  let _person = personKey || "person";
  let mappedClass = {};
  global._.forOwn(mapping, function(key, value) {
    mappedClass[value] = {
      get() {
        let person = global._.get(this, _person);
        return getPersonInfo(person, key);
      },
      set(value) {
        let person = global._.get(this, _person);
        return setPersonInfo(person, key, value);
      }
    };
  });
  return mappedClass;
}
function mapProposal(mapping, categoryKey) {
  let _category = categoryKey || "category";
  let mappedClass = {};
  global._.forOwn(mapping, function(key, value) {
    mappedClass[value] = function() {
      return global._.get(this, _category + ".proposals." + key, []);
    };
  });
  return mappedClass;
}
function getPersonInfo(person, personInfoTypeId) {
  if (!person || !person.personInfos) {
    return null;
  }
  for (let i = 0; i < person.personInfos.length; i++) {
    if (person.personInfos[i].personInfoTypeId == personInfoTypeId) {
      return person.personInfos[i].value;
    }
  }
}
function setPersonInfo(person, personInfoTypeId, personInfoValue) {
  if (!person.personInfos) {
    person.personInfos = new Array();
  }
  let found = false;
  for (let i = 0; i < person.personInfos.length; i++) {
    if (person.personInfos[i].personInfoTypeId == personInfoTypeId) {
      person.personInfos[i].value = personInfoValue;
      found = true;
      break;
    }
  }
  if (!found) {
    person.personInfos.push({
      personInfoTypeId: personInfoTypeId,
      value: personInfoValue
    });
  }
}
function forEachCartItem(object, callback) {
  if (!object) {
    return;
  }
  if (object.sectors) {
    for (let i = 0; i < object.sectors.length; i++) {
      if (forEachCartItem(object.sectors[i], callback)) return;
    }
  } else if (object.suborders) {
    for (let i = 0; i < object.suborders.length; i++) {
      if (forEachCartItem(object.suborders[i], callback)) return;
    }
  } else if (object.cartItems) {
    if (forEachCartItem(object.cartItems, callback)) return;
  } else if (object.suborderItems) {
    if (forEachCartItem(object.suborderItems, callback)) return;
  } else if (object.length) {
    for (let i = 0; i < object.length; i++) {
      try {
        if (callback(object[i])) return;
      } catch (e) {
        console.log(e);
      }
    }
  }
}
function filterProductsInCart(products, cart) {
  let filteredProducts = [];
  for (let f = 0; f < products.length; f++) {
    let product = products[f];
    let found = false;
    forEachCartItem(cart, function(item) {
      if (item.product.productId == product.productId) {
        found = true;
      }
    });
    if (!found) {
      filteredProducts.push(product);
    }
  }
  return filteredProducts;
}
export {
  mapPersonInfo,
  mapProposal,
  getPersonInfo,
  setPersonInfo,
  forEachCartItem,
  filterProductsInCart
};
