import CartService from "~/service/cartService";
import DeliveryService from "~/service/deliveryService";
import SecurityService from "~/service/securityService";
import AnalyticsService from "~/service/analyticsService";
import CustomMarketSuiteService from "@/service/marketsuiteCustomService";
import ExportDataService from "~/service/expordDataService";

const SET_CART = "SET_CART";
const SET_AUTH = "SET_AUTH";
const SET_CATEGORY_ID = "SET_CATEGORY_ID";
const SET_IMAGES = "SET_IMAGES";

const state = {
  cart: {},
  categoryId: null,
  hasImages: false
};

const getters = {
  isAuthenticated: state => {
    return state.cart && state.cart.user != null && state.cart.user.userId > -1;
  },
  isSsoUser: state => {
    return state.cart.ssoUser;
  },
  isMasterAccount: state => {
    return (
      state.cart.user.userType.userTypeId === "1" ||
      state.cart.user.userType.userTypeId === "2"
    );
  },
  getUser: state => {
    return global._.get(state.cart, "user");
  },
  getAddressId: state => {
    return state.cart.shippingAddress
      ? state.cart.shippingAddress.addressId
      : 0;
  },
  canChangeAddress: () => {
    return true;
  },
  getWarehouseId: state => {
    return global._.get(state.cart, "warehouse.warehouseId", 0);
  },
  getDeliveryServiceId: state => {
    return state.cart.shippingAddress
      ? state.cart.shippingAddress.deliveryServiceId
      : 0;
  },
  getTimeslotId: state => {
    return state.cart.timeslot
      ? state.cart.timeslot.date + state.cart.timeslot.timeslotId
      : 0;
  },
  cacheBurst: state => {
    let hash =
      "w" +
      global._.get(state.cart, "warehouse.warehouseId", 0) +
      "d" +
      global._.get(state.cart, "timeslot.date", 0) +
      "t" +
      global._.get(state.cart, "state.cart.timeslot.timeslotId", 0);
    return hash;
  },
  hasFavorites: state => {
    return global._.get(state.cart, "user.hasFavorites", false);
  },
  getCartStatus: state => {
    return state.cart.cartStatusId;
  },
  isTimeslotAssigned: state => {
    return !global._.get(state.cart, "timeslot.autoAssigned");
  },
  isAddressAssigned: state => {
    return !global._.get(state.cart, "shippingAddress.autoAssigned");
  },
  getItemByProductId: state => productId => {
    if (state.cart.cartItems) {
      for (let x = 0; x < state.cart.cartItems.length; x++) {
        let cartItem = state.cart.cartItems[x];
        if (cartItem.product.productId === productId) {
          return cartItem;
        }
      }
    } else if (state.cart.sectors) {
      for (let y = 0; y < state.cart.sectors.length; y++) {
        let sector = state.cart.sectors[y];
        for (let x = 0; x < sector.cartItems.length; x++) {
          let cartItem = sector.cartItems[x];
          if (cartItem.product.productId === productId) {
            return cartItem;
          }
        }
      }
    }
    return null;
  },
  getSelectedService: state => {
    return state.cart.shippingAddress.deliveryServiceId;
  },
  needInvoice: state => {
    return state.cart.cartInfos.need_invoice === "true";
  },
  profileLevel: state => {
    return state.cart && state.cart.user && state.cart.user.profile
      ? state.cart.user.profile.level
      : 0;
  },
  getItemsCount: state => {
    if (state.cart.cartItems) {
      return state.cart.cartItems.length;
    } else return 0;
  },
  getProducts: state => {
    if (state.cart.cartItems) {
      return state.cart.cartItems;
    } else return [];
  },
  getCartId: state => {
    if (state.cart.cartId) {
      return state.cart.cartId;
    }
  },
  getHasImages: state => {
    return state.hasImages;
  }
};

const actions = {
  async doLogin({ commit }, payload) {
    let data = await SecurityService.login(
      payload.email,
      payload.password,
      payload.rememberMe
    );

    if (data.user) {
      commit(SET_CART, data);
      if (data.cartItems.length > 0) {
        commit(SET_CATEGORY_ID, data.cartItems[0].product.categoryId);
      }
      AnalyticsService.login(data);
    }
    return data;
  },
  async doLogout({ commit }) {
    try {
      let data = await SecurityService.logout();
      AnalyticsService.logout();
      commit(SET_CART, {});
      //window.location.reload();
      return data;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: "errors.errorLogout"
      });
      return null;
    }
  },
  async doTechLogin({ commit }, payload) {
    let data = await SecurityService.techLogin(payload.email, payload.password);

    if (data.data.user) {
      commit(SET_CART, data.data);
    }
    return data;
  },
  async setAddress({ commit }, params) {
    try {
      const data = await DeliveryService.setAddress(
        params.addressType,
        params.addressId
      );
      if (data) {
        const cart = await CartService.getCart();
        commit(SET_CART, cart);
        AnalyticsService.selectAddress(cart);
        return data;
      }
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: "errors.errorSettingAddress"
      });
      return null;
    }
  },
  async setTimeslot({ commit }, params) {
    try {
      const data = await DeliveryService.setTimeslot(
        params.dateIso,
        params.timeslotId
      );
      if (data) {
        const cart = await CartService.getCart();
        commit(SET_CART, cart);
        AnalyticsService.selectTimeslot(cart);
        return data;
      }
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: "errors.errorSettingTimeslot"
      });
      return null;
    }
  },
  async loadCart({ commit }) {
    try {
      const cart = await CartService.getCart();
      commit(SET_CART, cart);
      if (cart.cartItems.length > 0) {
        commit(SET_CATEGORY_ID, cart.cartItems[0].product.categoryId);
      }
      AnalyticsService.initCustomDimension(cart);
      return cart;
    } catch (err) {
      if (this.isAuthenticated) {
        global.EventBus.$emit("error", {
          error: err,
          message: "errors.errorLoadingCart"
        });
      }
      return null;
    }
  },
  async lockCart({ commit }) {
    let cart = await CartService.lockCart();
    commit(SET_CART, cart);
    return cart;
  },
  async buyCart({ commit }, params) {
    let order = await CartService.buy(params);
    const cart = await CartService.getCart();
    commit(SET_CART, cart);
    return order;
  },
  async getCart({ commit }) {
    let cart = await CartService.getCart();
    commit(SET_CART, cart);
    return cart;
  },
  async setCartInfo({ commit }, params) {
    // for (var key of Object.keys(params)) {
    //   console.log(key, params[key]);
    // }
    // console.log("commit", commit);
    try {
      let cart;
      for (var key of Object.keys(params)) {
        cart = await CartService.setCartInfo(key, params[key]);
        AnalyticsService.checkoutOptions(2, key, params[key]);
      }
      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: "errors.errorSettingCartInfo"
      });
      return null;
    }
  },
  async setCartItemInfo({ commit }, params) {
    try {
      let cart = await CartService.setCartItemInfo(
        params.items,
        params.name,
        params.value
      );

      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: "errors.errorSettingCartInfo"
      });
      return null;
    }
  },
  async addProduct({ commit, state }, params) {
    if (state.cart.cartItems.length > 99) {
      global.EventBus.$emit("error", {
        message: "cart.maxProductsInCart"
      });
    } else {
      try {
        const cart = await CartService.addProduct(
          params.product,
          params.quantity
        );
        commit(SET_CART, cart);
        AnalyticsService.addToCart([
          { product: params.product, quantity: params.quantity }
        ]);
        return cart;
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: "cart.productAddError"
        });
        return null;
      }
    }
  },
  async addRemoveProduct({ dispatch }, item, quantity) {
    var items = [{ product: item, quantity: quantity }];
    await dispatch("addRemoveProducts", items);
  },
  async addRemoveProducts({ commit }, items) {
    try {
      const cart = await CustomMarketSuiteService.addProductsReset(items);
      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: "cart.productAddError"
      });
      return null;
    }
  },
  async addProducts({ commit, state }, items) {
    if (state.cart.cartItems.length > 99) {
      global.EventBus.$emit("error", {
        message: "cart.maxProductsInCart"
      });
    } else {
      if (state.cart.cartItems.length + items.length > 100) {
        let dif = 100 - state.cart.cartItems.length;
        items = items.slice(0, dif);
        global.EventBus.$emit("error", {
          message: "cart.maxProductsInCart"
        });
      }
      try {
        const cart = await CartService.addProducts(items);
        commit(SET_CART, cart);
        return cart;
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: "cart.productAddError"
        });
        return null;
      }
    }
  },
  async addGiftCode({ commit }, giftCodeId) {
    try {
      const cart = await CartService.addGiftCode(giftCodeId);
      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      // global.EventBus.$emit("error", {
      //   error: err,
      //   message: "errors.errorAddingProduct"
      // });
      return null;
    }
  },
  async sendGiftCode({ commit }, giftCode) {
    try {
      const cart = await CartService.sendGiftCode(giftCode);
      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      // global.EventBus.$emit("error", {
      //   error: err,
      //   message: "errors.errorAddingProduct"
      // });
      return null;
    }
  },
  async removeGiftCode({ commit }, giftCodeId) {
    try {
      const cart = await CartService.removeGiftCode(giftCodeId);

      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      // global.EventBus.$emit("error", {
      //   error: err,
      //   message: "errors.errorAddingProduct"
      // });
      return null;
    }
  },
  async addProductsFromOrder({ commit }, orderId) {
    try {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllFromOrder")
      });
      if (res) {
        const cart = await CartService.allProductsFromOrder(orderId);
        commit(SET_CART, cart);
        return cart;
      }
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: "errors.errorAddingProductFromOrder"
      });
      return null;
    }
  },
  async addProductsFromWishlist({ commit }, listId) {
    try {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        const cart = await CartService.allProductsFromWishlist(listId);
        commit(SET_CART, cart);
        return cart;
      }
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: "errors.errorAddingProductFromList"
      });
      return null;
    }
  },
  async setItem({ commit }, params) {
    try {
      const cart = await CartService.setItem(
        params.item,
        params.quantity,
        params.isMinus
      );
      commit(SET_CART, cart);
      AnalyticsService.addToCart([
        {
          product: params.item.product,
          quantity: params.quantity - params.item.quantity
        }
      ]);
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: "errors.errorSettingProduct"
      });
      return null;
    }
  },
  async removeItem({ commit, state }, product) {
    let item = state.cart.cartItems.find(
      item => item.product.productId === product.productId
    );
    try {
      const cart = await CartService.removeItem(item);
      commit(SET_CART, cart);
      AnalyticsService.removeFromCart([item]);
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: "errors.errorRemovingProduct"
      });
      return null;
    }
  },
  async emptyCart({ commit, state }) {
    try {
      if (state.cart.totalItems > 0) {
        const cart = await CartService.emptyCart(state.cart);

        commit(SET_CART, cart);
        return cart;
      }
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: "errors.emptyCart"
      });
      return null;
    }
  },
  async updateAuth({ commit, state }) {
    if (state.cart.user) {
      commit(SET_AUTH, -1);
    }
  },
  async hasImagesInContract({ commit, state }) {
    let data = await ExportDataService.getAvailableImageTypeShots(
      state.cart.cartId
    );
    commit(SET_IMAGES, data.skip_image == "false");
  }
};

const mutations = {
  [SET_CART](state, value) {
    state.cart = value;
  },
  [SET_CATEGORY_ID](state, value) {
    state.categoryId = value;
  },
  [SET_AUTH](state, value) {
    state.cart.user.userId = value;
  },
  updateSelectedService: (state, value) => {
    state.selectedService = value;
  },
  [SET_IMAGES](state, value) {
    state.hasImages = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
