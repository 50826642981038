import axios from "@/plugins/axios";

export default {
  getMenuByLocation(location) {
    let url = `/p/wp-json/wp-api-menus/v2/menu-locations/${location}`;
    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  getSlider(name, date, warehouseId, deliveryServiceId) {
    let roundedDate = date
      .startOf("day")
      .add(12, "h")
      .unix();
    let url = `/p/wp-json/wp/v2/new_slides/${name}/${roundedDate}/${warehouseId}`;
    return axios.get(url, {
      params: {
        deliveryServiceId: deliveryServiceId
      }
    });
  },
  getPage(slug) {
    return axios
      .get("/p/wp-json/wp/v2/pages", { params: { slug: slug } })
      .then(response => response.data)
      .catch(err => err);
  },
  // getCustomPostByFilters(customPostName, filters) {
  //   let url = `/p/wp-json/wp/v2/${customPostName}`;
  //   let params = {};
  //   if (filters) {
  //     filters.forEach(
  //       filter => (params[filter.filterName] = filter.filterValue)
  //     );
  //   }
  //   return axios.get(url, { params: params }).then(response => {
  //     if (response.data) {
  //       return response.data;
  //     }
  //   });
  // },
  getCustomPostByFilters(customPostName, filters) {
    let url = `/p/wp-json/wp/v2/${customPostName}`;
    let params = {};
    if (filters) {
      filters.forEach(
        filter => (params[filter.filterName] = filter.filterValue)
      );
    }
    return axios.get(url, { params: params }).then(response => {
      if (response) {
        return {
          data: response.data,
          page: {
            totItems: parseInt(response.headers["x-wp-total"]),
            totPages: parseInt(response.headers["x-wp-totalpages"])
          }
        };
      }
    });
  },
  getCustomPostBySlug(customPostName, slug) {
    let url = `/p/wp-json/wp/v2/${customPostName}`;
    return axios.get(url, { params: { slug: slug } }).then(response => {
      return response.data;
    });
  },
  getForm(formId) {
    let url = "/p/wp-json/wp/v2/get_mail_pars";
    return axios.get(url, { params: { form_id: formId } }).then(response => {
      return response.data.req.data;
    });
  },
  sendForm(data) {
    let url = "/p/wp-admin/admin-ajax.php";
    const formData = new FormData();
    formData.append("security", data.nonce);
    formData.append(
      "formData",
      JSON.stringify({ id: data.form_id, fields: data.fields })
    );
    formData.append("action", "nf_ajax_submit");
    axios.post(url, formData).then(response => {
      global.EventBus.$emit("success", {
        message: response.data.data.actions.success_message
      });
      return response.data.data;
    });
  }
};
