/* eslint-disable no-debugger */
//import Login from "@/components/Login";
import ExternalLogin from "@/components/ExternalLogin";
//import SecurityService from "@/commons/service/securityService";
import TimeslotSelector from "@/components/delivery/TimeslotSelector";
import AddressSelector from "@/components/delivery/AddressSelector";
import CartService from "~/service/cartService";
import CompanySelect from "@/components/navigation/CompanySelect";
import Login from "@/components/Login";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      config: global.config,
      dialogInstance: null,
      dialogOpen: false
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      isTimeslotAssigned: "cart/isTimeslotAssigned",
      isAddressAssigned: "cart/isAddressAssigned"
    })
  },
  methods: {
    async openDialog(component, properties, fullscreen) {
      var defaultConfig = {
        waitForResult: false,
        fullscreen:
          fullscreen !== undefined ? fullscreen : this.$vuetify.breakpoint.xs
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = await this.$dialog.show(component, config);
      const result = await this.dialogInstance.wait();
      return result;
    },
    async closeDialog() {
      await this.dialogInstance.close();
      return true;
    },
    async isLoggedIn(operation) {
      if (await CartService.isLoggedIn(operation)) {
        global.EventBus.$emit("restart");
      }
    },
    async setTimeslot() {
      let result = await this.openDialog(TimeslotSelector, {
        waitForResult: true,
        width: 750
      });
      if (result == "setAddress") {
        await this.setAddress();
        return await (this.isTimeslotAssigned ? true : this.setTimeslot());
      }
      return result;
    },
    async setAddress() {
      let result = await this.openDialog(AddressSelector, {
        waitForResult: true,
        width: 700
      });
      if (result == "setTimeslot") {
        this.setTimeslot();
        return false;
      }
      return result;
    },
    async setCompany() {
      let result = await this.openDialog(CompanySelect, {
        waitForResult: true,
        width: 700,
        persistent: true
      });
      console.log(result);
    },
    async techLogin() {
      let result = await this.openDialog(Login, {
        waitForResult: true,
        width: 600
      });
      console.log(result);
    },
    async doLogin(operation) {
      this.isLoggedIn(operation);
      // let result = await this.openDialog(Login, {
      //   waitForResult: true,
      //   width: 600
      // });
      // return result;
      if (!this.dialogOpen) {
        this.dialogOpen = true;
        let result = await this.openDialog(ExternalLogin, {
          waitForResult: true,
          width: 600
        });
        this.dialogOpen = false;
        return result;
      }
      // const loginRedirectUrl = await SecurityService.externalLogin();
      // window.location.replace(loginRedirectUrl);
    },
    async needAddress() {
      return await (this.isAddressAssigned ? true : this.setAddress());
    },
    async needTimeslot() {
      return await (this.isTimeslotAssigned ? true : this.setTimeslot());
    },
    async needLogin(operation) {
      var _this = this;
      return await (_this.isAuthenticated ? true : _this.doLogin(operation));
    }
  },
  created() {
    this.config = global.config;
  }
};
