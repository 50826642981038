<template>
  <section v-if="hasNotAgreedYet || reopenCookies">
    <section id="cookie-bar" :class="{ moreCookies: isMoreCookies }">
      <div>
        <v-container fluid>
          <div>
            <div>
              <!-- Cookie BASE -->
              <div id="cookie-bar-base" :class="{ hide: isMoreCookies }">
                <title>{{ $t("cookies.title") }}</title>
                <v-row>
                  <v-col cols="12" md="9">
                    <!-- <div v-html="$t('cookies.content')">
                    </div> -->
                    <i18n path="cookies.content" tag="div">
                      <router-link
                        :to="{
                          name: 'Page',
                          params: { pageName: 'cookie-policy' }
                        }"
                      >
                        {{ $t("cookies.linkCookiePolicy") }}
                      </router-link>
                    </i18n>
                  </v-col>
                  <v-col cols="12" md="3" class="d-flex flex-column">
                    <v-btn
                      color="primary"
                      depressed
                      x-large
                      class="text-uppercase mb-3"
                      @click="setAllCookiesPreferences(true)"
                    >
                      {{ $t("cookies.btnAccept") }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      depressed
                      outlined
                      x-large
                      class="text-uppercase"
                      @click="openMoreCookies()"
                    >
                      {{ $t("cookies.btnMoreOption") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <!-- Cookie MORE -->
              <div id="cookie-bar-more" :class="{ hide: !isMoreCookies }">
                <title>{{ $t("cookies.moreOptionTitle") }}</title>
                <v-row class="align-center">
                  <v-col cols="12" md="7">
                    <!-- <div v-html="$t('cookies.moreOptionSubtitle')"> -->
                    <div>
                      <i18n path="cookies.moreOptionSubtitle" :tag="false">
                        <router-link
                          @click.native="openMoreCookies"
                          :to="{
                            name: 'Page',
                            params: { pageName: 'cookie-policy' }
                          }"
                        >
                          {{ $t("cookies.linkCookiePolicy") }}
                        </router-link>
                      </i18n>
                    </div>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-btn
                          color="primary"
                          outlined
                          depressed
                          x-large
                          class="bg-white text-uppercase mb-3"
                          @click="setAllCookiesPreferences(false)"
                        >
                          {{ $t("cookies.btnRefuseAll") }}
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-btn
                          color="primary"
                          depressed
                          x-large
                          class="text-uppercase"
                          @click="setAllCookiesPreferences(true)"
                        >
                          {{ $t("cookies.btnAcceptAll") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <div>
                  <div>
                    <v-row class="align-center mb-2">
                      <v-col cols="12" md="9">
                        <div>
                          {{ $t("cookies.cookieTypeTitle1") }}
                        </div>
                      </v-col>
                      <v-col cols="12" md="3">
                        <div>
                          <b
                            ><i>{{ $t("cookies.strectlyNecessary") }}</i></b
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <p>
                      {{ $t("cookies.cookieTypeContent1") }}
                    </p>
                  </div>

                  <div>
                    <v-row class="align-center mb-2">
                      <v-col cols="12" md="9">
                        <div>
                          {{ $t("cookies.cookieTypeTitle2") }}
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        class="d-flex justify-start justify-md-end"
                      >
                        <v-switch
                          v-model="cookiesPreferences.analytics"
                          inset
                          hide-details
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <p>
                      {{ $t("cookies.cookieTypeContent2") }}
                    </p>
                  </div>

                  <div>
                    <v-row class="align-center mb-2">
                      <v-col cols="12" md="9">
                        <div>
                          {{ $t("cookies.cookieTypeTitle3") }}
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        class="d-flex justify-start justify-md-end"
                      >
                        <v-switch
                          v-model="cookiesPreferences.profilation"
                          inset
                          hide-details
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <p>
                      {{ $t("cookies.cookieTypeContent3") }}
                    </p>
                  </div>
                </div>
                <v-row>
                  <v-col cols="12" class="justify-end">
                    <v-btn
                      color="primary"
                      x-large
                      depressed
                      class="text-uppercase"
                      @click="saveCookiesPreferences()"
                    >
                      {{ $t("cookies.btnSaveAndExit") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-container>
      </div>
    </section>
  </section>
</template>
<style scoped lang="scss">
#cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-top: 1px solid #e0e0e0;
  background-color: #fafafa;
  p {
    margin-top: 0;
  }
  title {
    display: block;
    font-size: 30px;
    font-weight: 200;
    height: 33px;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .main-content {
    padding-bottom: 0;
    margin-bottom: 0;
    max-width: 1280px;
    margin: 20px auto;
    .main-content__wrapper {
      padding: 15px;
    }
  }
  .cookie-list {
    margin-top: 30px;
    .cookie-item {
      background-color: #f5f5f5;
      border-top: 1px solid #e0e0e0;
      padding: 20px;
      //   margin-bottom: 10px;
      .label-text {
        font-weight: normal;
        display: inline-block;
        margin-right: 15px;
        width: 80px;
      }
      .v-input {
        margin-top: 0;
      }
    }
    .cookie-type {
      font-size: 24px;
      //   margin-bottom: 10px;
      font-weight: 300;
      line-height: 1.1;
      // text-transform: uppercase;
    }
  }
}

.main-wrapper {
  transition: transform 250ms ease-in-out;
  padding-left: 210px;
  position: relative;
}

.mb20 {
  margin-bottom: 20px;
}
.mt30 {
  margin-top: 30px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      transition: 0.4s;
    }
  }
  .slider.round {
    border-radius: 22px;
    &:before {
      border-radius: 50%;
    }
  }
  .slider.round.active {
    background-color: #ed7c20;
    &:before {
      left: 20px;
    }
  }
}
.hide {
  display: none;
}
//767px
@media (max-width: 1140px) {
  #cookie-bar {
    zoom: 0.85;
    overflow-y: scroll;
    overflow-x: hidden;
    &.moreCookies {
      top: 0;
    }
  }
  .main-wrapper {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .text-right {
    text-align: left;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  #cookie-bar-more {
    .v-btn {
      width: 100%;
    }
  }
}
</style>
<script>
import { mapActions } from "vuex";
// eslint-disable-next-line no-unused-vars
import facebookInit from "../plugins/facebook";
// eslint-disable-next-line no-unused-vars
import hotjarInit from "../plugins/hotjar";
import gtagUtils from "../plugins/analytics";

export default {
  name: "CookieConsent",
  data() {
    return {
      isMoreCookies: false,
      hasNotAgreedYet: true,
      reopenCookies: false,
      cookiesPreferences: {}
    };
  },
  props: {
    isApp: { type: Boolean, required: true }
  },
  methods: {
    ...mapActions({
      updateCookiePreferences: "app/updateCookiePreferences",
      updatedReopenCookies: "app/updatedReopenCookies"
    }),
    openMoreCookies() {
      this.isMoreCookies = !this.isMoreCookies;
    },
    setAllCookiesPreferences(val) {
      if (this.cookiesPreferences) {
        for (var key in this.cookiesPreferences) {
          if (key !== "technical" || key !== "saveDate") {
            this.cookiesPreferences[key] = val;
          }
        }
        this.saveCookiesPreferences();
      }
    },
    saveCookiesPreferences() {
      this.hasNotAgreedYet = false;
      this.updatedReopenCookies(false);
      this.cookiesPreferences.saveDate = this.$DateTime.now().toISO();
      this.updateCookiePreferences(this.cookiesPreferences);
      this.injectScripts();
    },
    injectScripts() {
      if (this.cookiesPreferences.analytics) {
        //hotjarInit();
      }
      if (this.cookiesPreferences.profilation) {
        gtagUtils.enable();
        facebookInit();
      } else {
        gtagUtils.disable();
      }
    }
  },
  created() {
    if (this.isApp) {
      // app mobile, abilito tutto
      this.hasNotAgreedYet = false;
      this.cookiesPreferences.analytics = this.cookiesPreferences.profilation = true;
      this.injectScripts();
    } else {
      // sito
      this.cookiesPreferences = this._.clone(
        this.$store.state.app.cookiePreferences
      );
      this.reopenCookies = this._.clone(this.$store.state.app.reopenCookies);
      // se passato meno di un anno dall'acettazione, usare i cookie salvati
      if (
        this.$DateTime.fromISO(this.cookiesPreferences.saveDate) >
        this.$DateTime.now().minus({ years: 1 })
      ) {
        this.hasNotAgreedYet = false;
        this.updatedReopenCookies(false);
        this.injectScripts();
      }
    }
  },
  watch: {
    "$store.state.app.reopenCookies"(value) {
      this.isMoreCookies = false;
      this.reopenCookies = this._.clone(value);
      this.cookiesPreferences = this._.clone(
        this.$store.state.app.cookiePreferences
      );
    }
  }
};
</script>
