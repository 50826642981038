import axios from "@/plugins/axios";
import { defaultParams } from "./defaultParams";

export default {
  getCategoryTree(filtered = global.config.filterEmptyCategory) {
    let params = {
      params: {
        filtered: filtered,
        depth: 5,
        ...defaultParams()
      }
    };
    return axios
      .get("/ebsn/api/category", params)
      .then(data => data?.data?.data?.categories)
      .catch(error => error);
  },
  getCategoryTreeSliced() {
    return axios
      .get("/ebsn/api/theone-counter/get-counters-info")
      .then(data => data?.data?.data)
      .catch(error => error);
  },
  getCategoryBySlug(slug, filtered = global.config.filterEmptyCategory) {
    // eslint-disable-next-line no-debugger
    // debugger;
    // let hash = Math.random()
    //   .toString(36)
    //   .replace(/[^a-z]+/g, "")
    //   .substr(0, 5);
    return axios
      .get("/ebsn/api/category", {
        params: { slug: slug, filtered: filtered, ...defaultParams() }
      })
      .then(data => {
        return data.data.data.category;
      });
  },
  getCategoryById(categoryId, filtered = global.config.filterEmptyCategory) {
    return axios
      .get("/ebsn/api/category", {
        params: {
          categoryId: categoryId,
          filtered: filtered,
          ...defaultParams()
        }
      })
      .then(data => {
        //console.log(data);
        return data.data.data.category;
      });
  },
  getProductsCount() {
    return axios.get("/ebsn/api/homepage/countProduct").then(data => {
      return data.data;
    });
  },
  getCategoriesCount() {
    return axios.get("/ebsn/api/homepage/countCategorie").then(data => {
      return data.data;
    });
  },
  getBrandsCount() {
    return axios.get("/ebsn/api/homepage/countBrands").then(data => {
      return data.data;
    });
  }
};
