import CartService from "./cartService";
/* eslint-disable no-debugger */
import axios from "@/plugins/axios";
// import store from "../store";

export default {
  refreshLogin() {
    return CartService.getCart().then(data => {
      if (data && data.user) {
        return true;
      }
    });
  },
  externalLogin() {
    return axios
      .get("/ebsn/api/saml2/do-login?relay_state_url=" + window.location.href)
      .then(data => {
        console.log(data);
        if (data.data.response.status == 0) {
          console.log("RedirectURL", data.data.data.loginRedirectUrl);
          return data.data.data.loginRedirectUrl;
        }
        return data.data.data;
      });
  },
  login(username, password, rememberMe) {
    let params = new URLSearchParams();
    params.append("login", username);
    params.append("password", password);
    params.append("remember_me", rememberMe);
    params.append("show_sectors", global.config.showSectors);
    return axios
      .post("/ebsn/api/auth/login", params)
      .then(data => {
        if (data.data.response.status == 0) {
          // eslint-disable-next-line no-undef
          let session = vm.$cookies.get("X-Ebsn-Session");
          window.localStorage.setItem("X-Ebsn-Session", session);
          // eslint-disable-next-line no-undef
          let account = vm.$cookies.get("X-Ebsn-Account", account);
          window.localStorage.setItem("X-Ebsn-Account", account);
        }
        return data.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  logout() {
    return axios
      .post(
        "/ebsn/api/saml2/do-logout?relay_state_url=" + window.location.origin
      )
      .then(data => {
        if (data.data.response.status == 0) {
          localStorage.removeItem("X-Ebsn-Account");
          localStorage.removeItem("X-Ebsn-Session");
        }
        return data.data.data;
      })
      .catch(data => {
        return data.response;
      });
  },
  techLogin(username, password) {
    let params = new URLSearchParams();
    params.append("key", username);
    params.append("secret", password);
    window.localStorage.removeItem("X-Ebsn-Session");
    return axios
      .post("/ebsn/api/auth/ext-theone/tech-login", params)
      .then(data => {
        // if (data.data.response.status == 0) {
        //   if()
        //   let session = data.headers["X-Ebsn-Session"];
        //   window.localStorage.setItem("X-Ebsn-Session", session);
        //   //console.log("SESSION", session);
        // }
        return data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
};
