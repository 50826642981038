<template>
  <v-container class="pa-0" @click="clicked">
    <div
      class="pl-1 pr-1 pb-12 d-flex justify-start align-center secondary darken-1"
    >
      <v-btn large icon @click="$emit('close')" color="white">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <h3 class="white--text">{{ $t("links.title") }}</h3>
    </div>
    <div
      class="pa-4 text-body-1 font-weight-bold"
      no-gutters
      v-for="(link, index) in links"
      v-bind:key="index"
    >
      <a class="text-decoration-none white--text" :href="link.url">{{
        link.title
      }}</a>
    </div>
    <v-divider></v-divider>
    <div class="pa-4 text-body-1 font-weight-bold" no-gutters>
      <a class="text-decoration-none white--text" href="/test4">Assistenza</a>
    </div>
  </v-container>
</template>

<script>
import clickHandler from "~/mixins/clickHandler";
export default {
  mixins: [clickHandler],
  props: ["links"],
  methods: {
    onLinkClick() {
      this.drawerLinks = !this.drawerLinks;
      this.$nextTick(() => {
        this.clicked();
      });
    }
  }
};
</script>
