import { mapGetters, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      getDeliveryServiceId: "cart/getDeliveryServiceId",
      getCartStatus: "cart/getCartStatus"
    }),
    shippingAddress() {
      let shippingAddress = this._.get(this.cart, "shippingAddress");
      if (shippingAddress) {
        return this.$t(
          "navbar.address." + shippingAddress.addressTypeId + ".format",
          shippingAddress
        );
      } else {
        return this.$t("navbar.noShippingAddress");
      }
    },
    timeslot() {
      let timeslot = this._.get(this.cart, "timeslot");
      if (!timeslot || timeslot.autoAssigned) {
        return this.$t("navbar.noTimeslot");
      } else {
        // var date = this.$dayjs(timeslot.date).format("dddd DD MMMM");
        var date = this.$te("navbar.timeslot.dateFormat")
          ? this.$DateTime
              .fromISO(timeslot.date)
              .toFormat(this.$t("navbar.timeslot.dateFormat"))
          : this.$DateTime.fromISO(timeslot.date).toFormat("dd MMMM");

        if (this.$te("navbar.timeslot.format")) {
          return this.$t("navbar.timeslot.format", {
            date: date,
            beginTime: timeslot.beginTime,
            endTime: timeslot.endTime,
            name: timeslot.name
          });
        }
        return date + " | " + timeslot.beginTime + " - " + timeslot.endTime;
      }
    },
    serviceImg() {
      return this.$t("navbar.service." + this.getDeliveryServiceId + ".icon", {
        color: "color"
      });
    },
    serviceImgMobile() {
      return this.$t("navbar.service." + this.getDeliveryServiceId + ".icon", {
        color: "white"
      });
    },
    serviceName() {
      return this.$t("navbar.service." + this.getDeliveryServiceId + ".name");
    },
    editableAddress() {
      return !(this.$route.name == "Payment" || this.getCartStatus == 7);
    },
    editableTimeslot() {
      return !(this.$route.name == "Payment");
    }
  },
  methods: {
    async openAddressSelector() {
      if (this.editableAddress) {
        if (await this.setAddress()) {
          await this.needTimeslot();
        }
      }
    },
    async openTimeslotSelector() {
      if (this.editableTimeslot) {
        await this.setTimeslot();
      }
    }
  }
};
