<template>
  <v-container fluid class="pa-0">
    <!-- LINK DA CMSERVICE -->
    <div class="white">
      <v-container>
        <v-row
          class="d-flex flex-row justify-space-between flex-wrap bottomLine"
        >
          <v-btn
            text
            small
            v-for="(link, index) in footerLinks"
            class="mx-1 primary--text text-capitalize navLinks"
            height="48"
            v-bind:key="index"
            @click="navigate(link)"
            >{{ link.title }}</v-btn
          >
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" lg="3">
            <v-icon
              v-for="(social, index) in socialLinks"
              large
              class="px-1"
              color="primary"
              :key="index"
              @click="navigateSocial(social)"
            >
              > {{ social.icon }}
            </v-icon>
          </v-col>
          <v-col cols="12" sm="8" lg="8">
            <div class="my-4">
              <a
                v-for="(link, index) in externalLinks"
                :key="index"
                :href="link.url"
                class="text-decoration-none link--text mr-4"
                :target="link.target"
                >{{ link.label }}</a
              >
            </div>
            <span
              class="text-body-2 grey--text text--darken-3"
              v-html="$t('copyright')"
            ></span>
            <!-- <i18n
              path="common.version"
              tag="span"
              class="text-body-2 primary--text"
              >{{ version }}</i18n
            > -->
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<style>
.btn_outlined {
  color: var(--v-grey-darken3) !important;
  font-weight: bold;
  border-color: var(--v-primary-base);
}
.btn_filled {
  color: white !important;
  font-weight: bold;
  background-color: var(--v-grey-darken3) !important;
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import login from "~/mixins/login";
import { mapActions } from "vuex";
export default {
  name: "Footer",
  mixins: [clickHandler, login],
  computed: {
    version() {
      return global.config.version;
    }
  },
  data() {
    return {
      footerLinks: [
        { title: "myHome", url: "/", params: "0" },
        { title: "Sfoglia", url: "/category" },
        { title: "Ricerca avanzata", url: "/advanced-search" },
        // { title: "Approfondisci", url: "/", params: "2" },
        // { title: "Assistenza", url: "/test1" },
        { title: "Contattaci", url: "/test1" }
      ],
      socialLinks: [
        {
          icon: "mdi-instagram",
          url: "https://www.instagram.com/gs1italyservizi/"
        },
        {
          icon: "mdi-facebook",
          url: "https://www.facebook.com/gs1italyservizi"
        },
        {
          icon: "mdi-linkedin",
          url: "https://www.linkedin.com/company/gs1-it-servizi/"
        },
        {
          icon: "mdi-youtube",
          url: "https://www.youtube.com/@gs1italyservizi"
        }
      ],
      externalLinks: [
        {
          label: "GS1 Italy Servizi",
          url: "https://servizi.gs1it.org/",
          target: "_blank"
        },
        {
          label: "Privacy policy",
          url: "https://servizi.gs1it.org/privacy-policy/",
          target: "_blank"
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      updatedReopenCookies: "app/updatedReopenCookies"
    }),
    reopenCookies() {
      this.updatedReopenCookies(true);
    },
    navigate(link) {
      this.$router.push({ path: link.url, query: { tab: link.params } });
    },
    navigateSocial(link) {
      window.open(link.url, "_blank");
    }
  }
};
</script>
<style scoped lang="scss">
.bottomLine {
  border-bottom: 1px solid $primary-l1;
}
.navLinks {
  font-weight: 500;
  font-size: 16px;
}
.filter-primary {
  filter: invert(14%) sepia(82%) saturate(1725%) hue-rotate(199deg)
    brightness(96%) contrast(109%);
}
</style>
