const routes = [
  {
    path: "/page/:pageName",
    name: "Page",
    component: () => import("@/views/wordpress/Page.vue")
  },
  {
    path: "/volantini",
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "Flyers",
        component: () => import("@/views/wordpress/Flyers.vue")
      },
      {
        path: "/volantini/:volantino",
        name: "Flyer",
        component: () => import("@/views/wordpress/Flyer.vue")
      }
    ]
  },
  {
    path: "/faq",
    meta: {
      breadCrumb: "Faq"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "FaqCategory",
        path: "",
        component: () => import("@/views/wordpress/FaqList.vue")
      },
      {
        path: ":categoryName",
        name: "FaqList",
        meta: {
          breadCrumb: "Dettaglio Faq"
        },
        component: () => import("@/views/wordpress/FaqList.vue")
      }
    ]
  }
];

export default routes;
