import CatalogService from "./catalogService";
import OrderService from "./orderService";
import axios from "@/plugins/axios";
import { forEachCartItem } from "~/service/ebsn.js";

function getCart() {
  let params = { show_sectors: global.config.showSectors };

  return axios
    .get("/ebsn/api/cart/view", { params: params })
    .then(data => {
      return data.data.data;
    })
    .catch(() => {
      return Promise.reject();
    });
}

function buy(args) {
  var params = {
    show_sectors: global.config.showSectors,
    store_id: 5
  };

  if (args) {
    if (args.userPaymenthAuthId) {
      params.user_payment_auth_id = args.userPaymenthAuthId;
    }
    if (args.paymentTypeId) {
      params.payment_type_id = args.paymentTypeId;
    }
    if (args.paymentData) {
      params.payment_data = JSON.stringify(args.paymentData);
    }
  }

  return axios
    .post("/ebsn/api/checkout/buy", null, { params: params })
    .then(data => {
      if (data.data.response.status == 0) {
        return data.data.data;
      } else {
        throw new Error(data.data.response.errors[0].error);
      }
    });
}

export default {
  getCart: getCart,
  buy: buy,
  isLoggedIn(operation) {
    let params = {
      show_sectors: global.config.showSectors,
      operation: operation
    };
    return axios
      .get("/ebsn/api/auth/test", { params: params })
      .then(data => {
        return data.data && data.data.user.userId > -1;
      })
      .catch(() => {
        return false;
      });
  },
  async addProduct(product, quantity) {
    var items = [{ product: product, quantity: quantity }];
    return this.addProducts(items);
  },
  async addProducts(items) {
    let params = {};
    params.items = [];

    for (var i = 0; i < items.length; i++) {
      let product = items[i].product;

      let decodedQuantity = CatalogService.encodeProductQuantity(
        product,
        items[i].quantity
      );

      items[i].originalQuantity = items[i].quantity;
      items[i].quantity = decodedQuantity;

      let item = {
        productId: product.productId,
        quantity: decodedQuantity,
        useWpId: product.wpId
      };

      if (product.deliveryWarehouse) {
        item["deliveryWarehouseId"] = product.deliveryWarehouse.warehouseId;
      }

      //creo parametro select options
      let optionArray = [];
      for (let x = 0; x < product.selectOptions.length; x++) {
        if (product.selectOptions[x].selected) {
          let option = product.selectOptions[x];
          optionArray.push(option.name + ": " + option.selected.key);
        }
      }

      if (!item.notes) {
        item.notes = optionArray.join(", ");
      }
      item.infos = {};
      item.infos.accept_alternatives = true;
      //creo custom parameter based on selectOption
      //calculate additional parameters based on selectedOptions
      for (let j = 0; j < product.selectOptions.length; j++) {
        let option = product.selectOptions[j];
        if (option.parameterName && option.selected && option.selected.value) {
          item[option.parameterName] = option.selected.value;
        }
      }
      params.items.push(item);
    }
    return axios
      .post("/ebsn/api/cart/add", params, {
        params: { store_id: 5, show_sectors: global.config.showSectors }
      })
      .then(data => {
        //show toaster..

        if (
          data &&
          data.data &&
          data.data.response &&
          data.data.response &&
          data.data.response.status === 0
        ) {
          // console.log(data.data);
          // const lastItemId = data.data.lastCartItem.itemId;
          // const
          //  AnalyticsService.addToCart(items);
          /* for (let i = 0; i < items.length; i++) {
            // const name = items[i].product.name;
            const fullName = CatalogService.getProductFullnameForToast(
              items[i].product
            );

            const quantity = items[i].originalQuantity;

            // const viewQuantity = CatalogService.getProductQuantityForToast(
            //   items[i]
            // );
            const unit = CatalogService.getProductUnitForToast(
              items[i].product
            );
            global.EventBus.$emit("success", {
              message: global.vm.$t("cart.productAddText", {
                quantity,
                unit,
                name: fullName
              })
            });
          }*/
        }
        return data.data.data;
      });
  },
  allProductsFromOrder(orderId) {
    return axios
      .post("/ebsn/api/cart/add-all-from-order", null, {
        params: {
          order_id: orderId,
          store_id: 5,
          show_sectors: global.config.showSectors
        }
      })
      .then(data => {
        //show toaster..
        global.EventBus.$emit("success", {
          message: "cart.productsAddFromOrder"
        });
        return data.data.data;
      });
  },
  allProductsFromWishlist(listId) {
    return axios
      .post("/ebsn/api/cart/add-all-from-wishlist", null, {
        params: {
          list_id: listId,
          store_id: 5,
          show_sectors: global.config.showSectors
        }
      })
      .then(data => {
        //show toaster..
        global.EventBus.$emit("success", {
          message: "cart.productsAddFromOrder"
        });
        return data.data.data;
      });
  },

  /**
   * * PROMO GIFT SECTION
   */
  sendGiftCode(giftCode) {
    return axios
      .get("/ebsn/api/cart-giftcertificate/check", {
        params: {
          code: giftCode,
          store_id: 5,
          show_sectors: global.config.showSectors
        }
      })
      .then(data => {
        //show toaster..
        global.EventBus.$emit("success", {
          message: global.vm.$t("cart.sendGiftCode", {
            name: giftCode
          })
        });
        return data.data.data;
      });
  },
  addGiftCode(giftCodeId) {
    return axios
      .post(
        "/ebsn/api/cart-giftcertificate/add_code",
        {},
        {
          params: {
            code: giftCodeId,
            store_id: 5,
            show_sectors: global.config.showSectors
          }
        }
      )
      .then(data => {
        //show toaster..
        // global.EventBus.$emit("success", {
        //   message: global.vm.$t("cart.sendGiftCode", {
        //     name: giftCode
        //   })
        // });
        return data.data.data;
      });
  },
  removeGiftCode(giftCodeId) {
    return axios
      .post(
        "/ebsn/api/cart-giftcertificate/remove_code",
        {},
        {
          params: {
            code: giftCodeId,
            store_id: 5,
            show_sectors: global.config.showSectors
          }
        }
      )
      .then(data => {
        return data.data.data;
      });
  },
  setItem(item, quantity, isMinus) {
    //calcolo la quantity corretta in base al tipo di prodotto
    var encodedQuantity = CatalogService.encodeProductQuantity(
      item.product,
      quantity
    );

    var params = {
      items: [
        {
          itemId: item.itemId,
          quantity: encodedQuantity
        }
      ]
    };
    return axios
      .post("/ebsn/api/cart/update", params, {
        params: { store_id: 5, show_sectors: global.config.showSectors }
      })
      .then(data => {
        // const viewQuantity = CatalogService.getProductQuantityForToast(item);
        const unit = CatalogService.getProductUnitForToast(item.product);
        const fullName = CatalogService.getProductFullnameForToast(
          item.product
        );

        if (!isMinus) {
          if (
            data &&
            data.data &&
            data.data.response &&
            data.data.response &&
            data.data.response.status === 0
          ) {
            // global.EventBus.$emit("success", {
            //   message: "cart.productAdd"
            // });
            // AnalyticsService.addToCart([item]);

            global.EventBus.$emit("success", {
              message: global.vm.$t("cart.productAddText", {
                quantity,
                unit,
                name: fullName
              })
            });
          }
        } else {
          // global.EventBus.$emit("success", {
          //   message: "cart.productRemoved"
          // });
          global.EventBus.$emit("success", {
            message: global.vm.$t("cart.productAddText", {
              quantity,
              name: fullName,
              unit
            })
          });
        }
        return data.data.data;
      });
  },
  removeItem(item) {
    let params = {
      items: [
        {
          itemId: item.itemId,
          productId: item.product.productId
        }
      ]
    };
    return axios
      .post("/ebsn/api/cart/delete", params, {
        params: { store_id: 5, show_sectors: global.config.showSectors }
      })
      .then(data => {
        // AnalyticsService.removeFromCart([item]);

        // global.EventBus.$emit("success", {
        //   message: "cart.productRemoved"
        // });
        return data.data.data;
      });
  },
  emptyCart(cart) {
    let items = [];
    forEachCartItem(cart, function(item) {
      items.push({ itemId: item.itemId, productId: item.product.productId });
    });
    // var params = { items: items };

    let params = {
      items: items
    };
    return axios
      .post("/ebsn/api/cart/delete", params, {
        params: { store_id: 5, show_sectors: global.config.showSectors }
      })
      .then(data => {
        // AnalyticsService.removeFromCart(items);

        // global.EventBus.$emit("success", {
        //   message: "cart.productsRemoved"
        // });
        return data.data.data;
      });
  },
  plus(product, qty) {
    let total;
    if (product) {
      if (
        product.productInfos.TIPOLOGIA == "Pezzo" ||
        product.productInfos.TIPOLOGIA == "Confezione"
      ) {
        let step = product.productInfos.QTY_MULT
          ? parseFloat(product.productInfos.QTY_MULT)
          : 1;
        let remainder = qty % step;
        total = qty ? qty - remainder + step : step;
        if (product.minAcq) {
          total = total >= product.minAcq ? total : product.minAcq;
        }
        // if (product.maxAcq) {
        //   total = total <= product.maxAcq ? total : product.maxAcq;
        // }
        // if (product.available) {
        //   total = total <= product.available ? total : product.available;
        // }
        return this.round(total);
      }
      if (product.productInfos.TIPOLOGIA == "Sfuso") {
        total = qty
          ? qty + parseFloat(product.productInfos.STEP_SELLING)
          : parseFloat(product.productInfos.WEIGHT_SELLING);

        return this.round(total);
      }
    }
  },
  minus(product, qty) {
    let total;
    if (
      product.productInfos.TIPOLOGIA == "Pezzo" ||
      product.productInfos.TIPOLOGIA == "Confezione"
    ) {
      let step = product.productInfos.QTY_MULT
        ? parseFloat(product.productInfos.QTY_MULT)
        : 1;
      let remainder = qty % step;
      if (remainder > 0) {
        total = qty - remainder;
      } else {
        total = qty ? qty - step : 0;
      }
      if (product.minAcq) {
        total = total >= product.minAcq ? total : product.minAcq;
      }
      return this.round(total);
    }
    if (product.productInfos.TIPOLOGIA == "Sfuso") {
      total = qty ? qty - parseFloat(product.productInfos.STEP_SELLING) : 0;

      total = this.round(total);
      return total >= parseFloat(product.productInfos.WEIGHT_SELLING)
        ? total
        : 0;
    }
  },
  round(total) {
    var totalReturn = (total * 1000).toFixed(0);
    return totalReturn / 1000;
  },
  lockCart() {
    return axios.post("/ebsn/api/checkout/prepare").then(data => {
      if (data.data.response.status === 0) {
        return data.data.data;
      } else {
        throw new Error(data.data.response.errors[0].error);
        //Promise.reject(new Error("errore"));
      }
    });
  },
  // getPaymentTypeList(orderId) {
  //   return axios
  //     .get("/ebsn/api/payment-type/list", { params: { order_id: orderId } })
  //     .then(data => {
  //       return data.data.data.payments;
  //     });
  // },
  getPreBilledAmount(paymentTypeId, orderId) {
    return axios
      .get("/ebsn/api/checkout/prebilled-amount", {
        params: {
          payment_type_id: paymentTypeId,
          order_id: orderId
        }
      })
      .then(function(data) {
        return data.data.data.prebilledAmount;
      });
  },
  getPackages() {
    return axios.get("/ebsn/api/packaging-type/list").then(data => {
      return data.data.data.products;
    });
  },
  getPaymentAuthList(paymentTypeId) {
    return axios
      .get("/ebsn/api/payment-type/list-auth", {
        params: { payment_type_id: paymentTypeId }
      })
      .then(data => {
        return data.data.data.auths;
      });
  },
  confirmOrderAndTrack(paymentTypeId, userPaymenthAuthId, paymentData) {
    return buy(paymentTypeId, userPaymenthAuthId, paymentData);
  },
  async modifyOrderAndTrack(
    orderId,
    paymentTypeId,
    userPaymenthAuthId,
    paymentData
  ) {
    const oldOrder = await OrderService.getOrder(orderId, true);
    // OrderService.getOrder(orderId, true)
    //   .then(oldOrder => {
    const buyresponse = await buy(
      paymentTypeId,
      userPaymenthAuthId,
      paymentData,
      oldOrder
    );
    return buyresponse;
    //     .then(data => {
    //       return data;
    //     })
    //     .catch(data => {
    //       return Promise.reject(data);
    //     });
    // })
    // .catch(data => {
    //   return Promise.reject(data.data);
    // });
  },
  setCartInfo(name, value) {
    let params = { property: name, value: value };
    return axios.post("/ebsn/api/cart/update-cart-info", params).then(data => {
      return data.data.data;
    });
  },
  setCartItemInfo(items, name, value) {
    var params = { items: [] };
    if (items instanceof Array) {
      items.forEach(addItemToParams);
    } else {
      addItemToParams(items);
    }
    return axios.post("/ebsn/api/cart/update-infos", params).then(data => {
      return data.data.data;
    });

    function addItemToParams(item) {
      let param = { itemId: item.itemId, infos: {} };
      param.infos[name] = value;
      params.items.push(param);
    }
  },
  getOriginalImages(productId) {
    return (
      axios({
        url: `/ebsn/api/delisted-product/download-images`,
        params: {
          productId: productId
        },
        method: "GET",
        responseType: "blob"
      })
        //return axios.get(`/ebsn/api/delisted-product/download-images`,{ params: params },{responseType: 'blob'})
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var contentDisposition = response.headers["content-disposition"];
          var zipFileName = "";
          if (
            contentDisposition &&
            contentDisposition.indexOf("attachment") !== -1
          ) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              zipFileName = matches[1].replace(/['"]/g, "");
            }
          }

          fileLink.href = fileURL;
          fileLink.setAttribute("download", zipFileName);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(function(error) {
          console.log(error);
        })
    );
  }
};
