import axios from "@/plugins/axios";
// const qs = require("qs");

export default {
  getUserDetail() {
    let url = "/ebsn/api/registration/user_detail";
    return axios
      .get(url)
      .then(response => response.data.data.user)
      .catch(err => err);
  },
  updateUserDetail(user) {
    let url = "/ebsn/api/registration/update_user_detail";
    return axios
      .post(url, user)
      .then(response => response.data.data.user)
      .catch(err => err);
  },
  resetPassword(email, token, password) {
    let url = "/ebsn/api/registration/password_reset";
    return axios
      .get(url, {
        params: { e_mail: email, reset_token: token, password: password }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  },
  updateBillingAddress(user) {
    let url = "/ebsn/api/registration/update_billing_address";
    return axios
      .post(url, user)
      .then(response => {
        console.log(response);
        return response.data.data.user;
      })
      .catch(err => {
        console.log(err);
        return err;
      });
  },
  getResetPasswordToken(email) {
    let url = "/ebsn/api/registration/password_reset_token_request";
    return axios
      .get(url, {
        params: { e_mail: email }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  },
  updateEmailRequest(newEmail) {
    let url = "/ebsn/api/registration/update_email_request";
    return axios.get(url, { params: { new_email: newEmail } }).then(data => {
      return data.data;
    });
  },
  updatePassword(oldPassword, newPassword) {
    let url = "/ebsn/api/registration/update_password";
    return axios
      .get(url, {
        params: { old_password: oldPassword, new_password: newPassword }
      })
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  registrationUser(user) {
    let url = "/ebsn/api/registration/register_user";
    return axios
      .post(url, user)
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  confirmRegistration(email, token) {
    let url = "/ebsn/api/registration/registration_confirm";
    return axios
      .get(url, {
        params: {
          e_mail: email,
          reg_control_code: token
        }
      })
      .then(response => {
        return response.data.response;
      });
  },
  checkRemoteVersion() {
    return axios.get("/version.json");
  },
  registrationResendEmail(email) {
    let url = "/ebsn/api/registration/registration_resend_email";
    return axios
      .get(url, {
        params: {
          e_mail: email
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  }
};
