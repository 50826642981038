export default {
  methods: {
    clicked(e) {
      var target = findAnchor(e.target, 1);
      if (target && global.config.domainWhitelist.includes(target.hostname)) {
        let resolved = this.$router.resolve({
          path: target.pathname
        });
        if (
          resolved &&
          resolved.route.matched.length > 0 &&
          resolved.route.name != "DefaultRoute"
        ) {
          e.preventDefault();
          try {
            this.$router.push(resolved.route);
          } catch (e) {
            console.log(e);
          }
        } else {
          if (this.$platform_is_cordova && target.href.indexOf("http") > -1) {
            //if app open on _system browser
            e.preventDefault();
            window.cordova.InAppBrowser.open(target.href, "_system");
          }
        }
      } else {
        if (this.$platform_is_cordova && target.href.indexOf("http") > -1) {
          //if app open on _system browser
          e.preventDefault();
          window.cordova.InAppBrowser.open(target.href, "_system");
        }
      }
      function findAnchor(target, i) {
        if (target && target.hostname) {
          return target;
        } else if (i < 5) {
          return findAnchor(target.parentElement, ++i);
        } else {
          return null;
        }
      }
    }
  }
};
