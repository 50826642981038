let routes = [
  {
    path: "/images/:slug?",
    name: "GetImages",
    component: () => import("@/components/images/GetImages.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      keepAlive: true
    }
  }
];

export default routes;
