const routes = [
  {
    path: "/not-found",
    name: "Not found",
    props: true,
    component: () => import("@/views/NotFound.vue"),
    meta: {
      load404: true
    }
  },
  {
    path: "/service-down",
    name: "Service Down",
    props: true,
    component: () => import("@/views/ServiceDown.vue"),
    meta: {
      load404: true
    }
  }
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("@/views/Login.vue"),
  //   meta: { registerButton: true }
  // }
];

export default routes;
