const INCREASE_PENDING_REQUESTS = "increasePendingRequests";
const DECREASE_PENDING_REQUESTS = "decreasePendingRequests";
const SET_USER_ADDRESSES = "setUserAddresses";
const UPDATED_DRAWER_RIGHT = "updatedDrawerRight";
const UPDATED_DRAWER_LEFT = "updatedDrawerLeft";
const UPDATED_DRAWER_LINKS = "updatedDrawerLinks";
const UPDATE_LAST_START_TIME = "updateLastStartTime";
const UPDATE_LAST_TUTORIAL_SEEN = "updateLastTutorialSeen";
const UPDATE_COOKIE_PREFERENCES = "updateCookiePreferences";
const UPDATE_REOPEN_COOKIE = "updatedReopenCookies";
const UPDATE_ORDERS_SENT_TO_ANALYTICS = "updateOrdersSentToAnalytics";
const ADD_ORDER_SENT_TO_ANALYTICS = "addOrderSentToAnalytics";
const state = {
  pendingRequests: { count: 0 },
  drawerLeft: null,
  drawerRight: null,
  drawerLinks: null,
  lastTutorialSeen: null,
  lastStartTime: null,
  ordersSentToAnalytics: [],
  cookiePreferences: {
    technical: true,
    analytics: false,
    profilation: false,
    saveDate: "1990-01-01T00:00:00.000+02:00"
  },
  reopenCookies: false,
  productPath: "metaData.product_description_main.DATASHEET"
};
const getters = {
  showOverlay: state => {
    return state.pendingRequests.count > 0;
  },
  needRestart: state => {
    return (
      new Date().getTime() - state.lastStartTime >
      global.config.reloadAfterMillis
    );
  },
  isOrderSentToAnalytics: state => orderId => {
    return state.ordersSentToAnalytics.includes(orderId);
  },
  getProductPath: state => {
    return state.productPath;
  }
};

const actions = {
  [INCREASE_PENDING_REQUESTS]({ commit }) {
    commit(INCREASE_PENDING_REQUESTS);
  },
  [DECREASE_PENDING_REQUESTS]({ commit }) {
    commit(DECREASE_PENDING_REQUESTS);
  },
  [SET_USER_ADDRESSES]({ commit }, addresses) {
    commit("[SET_USER_ADDRESSES]", addresses);
  },
  [UPDATED_DRAWER_RIGHT]({ commit }, value) {
    commit(UPDATED_DRAWER_RIGHT, value);
  },
  [UPDATED_DRAWER_LEFT]({ commit }, value) {
    commit(UPDATED_DRAWER_LEFT, value);
  },
  [UPDATED_DRAWER_LINKS]({ commit }, value) {
    commit(UPDATED_DRAWER_LINKS, value);
  },
  [UPDATE_LAST_START_TIME]({ commit }) {
    let value = new Date().getTime();
    commit(UPDATE_LAST_START_TIME, value);
  },
  [UPDATE_LAST_TUTORIAL_SEEN]({ commit }, value) {
    commit(UPDATE_LAST_TUTORIAL_SEEN, value);
  },
  [UPDATE_COOKIE_PREFERENCES]({ commit }, value) {
    commit(UPDATE_COOKIE_PREFERENCES, value);
  },
  [UPDATE_REOPEN_COOKIE]({ commit }, value) {
    commit(UPDATE_REOPEN_COOKIE, value);
  },
  [UPDATE_ORDERS_SENT_TO_ANALYTICS]({ commit }, value) {
    commit(UPDATE_ORDERS_SENT_TO_ANALYTICS, value);
  },
  [ADD_ORDER_SENT_TO_ANALYTICS]({ commit }, orderId) {
    commit(ADD_ORDER_SENT_TO_ANALYTICS, orderId);
  }
};
const mutations = {
  [INCREASE_PENDING_REQUESTS]: state => {
    state.pendingRequests.count++;
  },
  [DECREASE_PENDING_REQUESTS]: state => {
    state.pendingRequests.count--;
    let value = new Date().getTime();
    state.lastStartTime = value;
    if (state.pendingRequests.count < 0) {
      state.pendingRequests.count = 0;
    }
  },
  [SET_USER_ADDRESSES]: (state, value) => {
    state.userAddresses = value;
  },
  [UPDATED_DRAWER_RIGHT]: (state, value) => {
    state.drawerRight = value;
  },
  [UPDATED_DRAWER_LEFT]: (state, value) => {
    state.drawerLeft = value;
  },
  [UPDATED_DRAWER_LINKS]: (state, value) => {
    state.drawerLinks = value;
  },
  [UPDATE_LAST_START_TIME]: (state, value) => {
    state.lastStartTime = value;
  },
  [UPDATE_LAST_TUTORIAL_SEEN]: (state, value) => {
    state.lastTutorialSeen = value;
  },
  [UPDATE_COOKIE_PREFERENCES]: (state, preferences) => {
    state.cookiePreferences = preferences;
  },
  [UPDATE_REOPEN_COOKIE]: (state, value) => {
    state.reopenCookies = value;
  },
  [UPDATE_ORDERS_SENT_TO_ANALYTICS]: (state, orders) => {
    state.ordersSentToAnalytics = orders;
  },
  [ADD_ORDER_SENT_TO_ANALYTICS]: (state, orderId) => {
    state.ordersSentToAnalytics.push(orderId);
    if (state.ordersSentToAnalytics.length > 20) {
      state.ordersSentToAnalytics.shift();
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
